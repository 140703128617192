import React, { useState } from 'react'
import { withFirebase } from '../../../services'

const NameModal = (props) => {

    const { firebase, userData, SweetAlert, setSweetAlert, setModalShow } = props

    const [username, setUsername] = useState(userData.name)
    const [isSaving, setIsSaving] = useState(false)
    const changeName = async (e) => {
        setIsSaving(true)
        e.preventDefault()
        const userId = firebase.auth.currentUser.uid
        try {
            let dataTobeSaved = { ...userData }
            dataTobeSaved.name = username
            let ref = firebase.db.collection("users").doc(userId);

            await ref.set(dataTobeSaved, { merge: true }).then(() => {
                setIsSaving(false)
                setModalShow(false)
                setSweetAlert({ 
                    ...SweetAlert, 
                     show: true,
                     title: "Name Successfully Updated.", 
                     type: "success", 
                     onConfirm: () => {
                        setSweetAlert({...SweetAlert, show: false})
                     } })
            }).catch((error) => {
                setIsSaving(false)
                console.log(error)
            });
        } catch (error) {
            console.log(error)
            setIsSaving(false)
        }

    }


    return (
        <div className="name">
        <form onSubmit={changeName}>
            <div className="form-control">
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className="inputText" required />
                <span className="floating-label">Name</span>
            </div>
            <input type="submit" id="email" name="email" value={isSaving ? "Saving..." : "Save Changes"} />
        </form>
    </div>
    )
}

export default withFirebase(NameModal)
