import React, { useState} from 'react'

//img
import logo from "../../assets/images/logo.png";
import plus from "../../assets/images/blue-plus.png";
import { withFirebase } from '../../services';

function Header(props) {
    const { firebase, user } = props;
    const [ShowMenu, setShowMenu] = useState(false)

    const doLogout = () => {
        firebase.dosignOut();
    }
    const UserMenu = () => {
        setShowMenu(!ShowMenu)
    }
                              

    return (
        <header id="header" className="header-differ">
            <div className="container">
                <div className="inner-header main-header">
                    <div className="logo">
                        <a href="/dashboard/list"><img src={logo} alt="Logo" /></a>
                    </div>
                    <div className="header-right">
                        <div className="header-right-left">
                            <ul>
                                {/* <li className="notification"><img src={alert} alt="" /></li>
                                <li className="help"><img src={help} alt="" /></li> */}
                                <li className="notification">
                                    <a href="/registration" className="btn-secondary" target="_blank">
                                        <p><img src={plus} alt="" /></p><span> Add New</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right-right">
                            <div className="user-profile-wrapper"
                                onClick={UserMenu}
                            >
                                <div className="current-user">
                                    <span className="user-image">
                                        <img src={user.profilePhoto ? user.profilePhoto : logo} alt="User" style={{ height: "45px", width: "45px" }} />
                                    </span>
                                    <span className="username">
                                        <h2>{user.name}</h2>
                                        <h5>Admin</h5>
                                    </span>
                                    <span className="dropdown-menu-arrow">
                                        <i className={ShowMenu ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="dropdown-menu" style={ShowMenu ? { display: "block" } : null}>
                                    <ul>
                                        {/* <li><a href="#">Profile</a></li>
                                        <li><a href="#">Setting</a></li> */}
                                        <li><a href={false} onClick={doLogout}>Logout</a></li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default withFirebase(Header)
