import React, { useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

function Loading() {
    const [progress, setProgress] = useState(100)
    return (
        <LoadingBar color="#B27803" progress={progress} onLoaderFinished={() => setProgress(0)} />
    )
}

export default Loading;