import React from 'react'
import Review from '../../../components/Generic/Review'
import CommentForm from './CommentForm'
const BlogReview = (props) => {

    const { bID, reviewData } = props;



    return (
        <div className="reviews-inner">
            <div className="left">
                <ul className="reviews-content">
                    {
                        reviewData?.map((review, i) => (

                            <Review doctorReview={false} key={i} review={review} docID={bID} />

                        ))
                    }
                </ul>
            </div>
            <div className="right">
                <CommentForm bID={bID} />
            </div>
        </div>
    )
}

export default BlogReview
