import React from 'react'
import { DOWNLOAD_IOS_APP } from '../../views/const'

function HeaderMenu({home, login, signup, loginfromuser, registerfromuser}) {
 
    return (
        <div className="header-menu login-header-menu">
            <ul>
                <li className={home && "active"}><a href={loginfromuser || registerfromuser ? "/" : "/dashboard"}>Home</a></li>
                <li className={signup && "active"}><a href={loginfromuser || registerfromuser ? "/user-role" : "/user-role"}>Sign Up</a></li>
                <li><a href="/#contactus">Contact Us</a></li>
                <li className={login && "active"}><a href={loginfromuser || registerfromuser ? "/user-login" : "/login"}>Login</a></li>
                <li className="down"><a href={DOWNLOAD_IOS_APP}><b>Download App</b></a></li>
            </ul>
        </div>
    )
}

export default HeaderMenu;