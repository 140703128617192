import React from 'react'

export const DoctorDescription = (props) => {

    const { data } = props;


    return (
        <div className="doc-description">
            <span className="graduation">
              {data?.name} graduated in {data?.graduationYear}.
            </span>

            <div className="education">
                 <h3 className="edu-title">Education</h3>
                <span className="edu-desc">{data?.university}</span>
            </div>

            <div className="courses">
                 <h3 className="edu-title">Courses</h3>
                 {
                     data?.courses?.map((course, i) => (
                        <span key={i} className="edu-desc">- {course}</span>
                     ))
                 }
            </div>

            <div className="experience">
            <h3 className="edu-title">Performed procedures</h3>
            <ul>
                {
                    data?.performedProcedures?.map((procedure, i) => (
                        <li key={i}>{procedure};</li>
                    ))
                }
                
            </ul>
            </div>
        </div>
    )
}
