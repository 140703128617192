import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Cropper from "react-cropper";
import '../../../node_modules/cropperjs/dist/cropper.css'

function PhotoUploadModal(props) {
    const { show, onHide, img, onSave, isSaving, setIsSaving } = props;
    const [previewSrc, setPreviewSrc] = useState(null)
    const [croppedPhoto, setCroppedPhoto] = useState(null)
    const [cropper, setCropper] = useState();


    // useEffect to get Image from the parent 
    // component and store in previewSrc state
    useEffect(() => {
        if (img !== null) {
            try {
                const reader = new FileReader()
                reader.onload = () => {
                    setPreviewSrc(reader.result)
                }
                reader.readAsDataURL(img)
            } catch (error) {

            }
        }
    }, [img])

    const handleClose = () => {
        setPreviewSrc(null)
        setCroppedPhoto(null)
        onSave(null);
        onHide();
    }

    // To set/save crop image to parent component
    const handleSubmit = () =>{
        if(setIsSaving){
            setIsSaving(true)
        }
        onSave(cropper.getCroppedCanvas().toDataURL());
    }
    return (
        <>
        {/* React Reusable Components for Image upload:
            usage :
            Resize or Crop the Image before the upload.
        */}
            <Modal show={show} onHide={onHide} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Add Professional Headshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div 
                        className="img-preview"
                        style={{ width: "100%", height: "100px" }}
                    />
                    <Cropper                // Cropper.js NPM Pakage
                        src={previewSrc}    // previewSrc is state var for the image which we Select from browse window. 
                        preview=".img-preview"
                        style={{ height: 200, width: '100%' }}
                        aspectRatio={1 / 1}
                        guides={true}
                        crop={(e) => { }}
                        onInitialized={(instance) => {
                            setCropper(instance);   // set new crop or resize image instance to state var name cropper
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" 
                        onClick={handleSubmit}>
                        {isSaving ? "Saving..." : "Save Changes"}
                     </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PhotoUploadModal
