import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

export const specialities = [
    "Acute/Critical Care",
    "Allergy and Immunology",
    "Anesthesiology",
    "Audiology",
    "Cardiology",
    "Dentistry",
    "Dermatology",
    "Radiology",
    "Endocrinology",
    "Emergency Medicine",
    "Ear Nose and Throat",
    "Family Medicine / Primary Care",
    "Gastroenterology",
    "Surgery",
    "Hematology",
    "Hospice and Palliative Medicine",
    "Internal Medicine",
    "Infectious Disease",
    "Medical Genetics",
    "Neonatology",
    "Neurology",
    "Nephrology",
    "Nuclear Medicine",
    "Obstetrics and Gynecology",
    "Occupational Therapy",
    "Ophthalmology",
    "Optometry",
    "Orthopedics",
    "Pathology",
    "Pain Medicine",
    "Pharmacology",
    "Pediatrics",
    "Physical Medicine and Rehabilitation",
    "Plastic Surgery",
    "Psychiatry / Mental Health",
    "Pulmonology",
    "Podiatry",
    "Rheumatology",
    "Sleep Medicine",
    "Sports Medicine",
    "Surgery",
    "Oncology",
    "Urology",
    "Veterinary Medicine",
    "Allied Health",
    "Bariatric Medicine"
]

const validate = values => {
    let errors = {};
    if (!values.txtName) {
        errors.txtName = true;
    }

    if (!values.txtEmail) {
        errors.txtEmail = true;
    }

    if (!values.txtMessage) {
        errors.txtMessage = true;
    }

    return errors;
};


export function DoctorContactUsModal(props) {
    const { show, onHide, onSubmit } = props;
    const DoctorContactUsForm = useFormik({
        initialValues: {
            txtName: "",
            txtEmail: "",
            txtMessage: "",
            specialties: "",
            type: "Healthcare provider"
        },
        onSubmit: values => {
            onSubmit(values)
        },
        validate
    });

    const onClose = () => {
        errors = null
        onHide();
    }

    let { handleSubmit, setFieldValue, errors, values, handleChange, touched } = DoctorContactUsForm;
    return (
        <>
            <Modal
                id="ABC"
                show={show}
                onHide={onClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body>
                    <div className="contact-model">
                        <h5 className="section-title">Contact us</h5>
                        <h6 className="conatc-overview">Please feel free to contact us if you have any questions</h6>
                        <form onSubmit={handleSubmit}>
                            <div className="contact-form">
                                <div className="contact-form-left">
                                    <div className="contact-form-wrapper">
                                        <label>Name:</label>
                                        <input type="text"
                                            name="txtName"
                                            className={touched.txtName && errors.txtName === true ? "contact-txt error" : "contact-txt"}
                                            onChange={handleChange}
                                            value={values.txtName}
                                        />
                                    </div>
                                    <div className="contact-form-wrapper">
                                        <label>Email:</label>
                                        <input type="email"
                                            name="txtEmail"
                                            className={touched.txtEmail && errors.txtEmail === true ? "contact-txt error" : "contact-txt"}
                                            onChange={handleChange}
                                            value={values.txtEmail}
                                        />
                                    </div>
                                    <div className="contact-form-wrapper">
                                        <label>Speciality:</label>
                                        <select className="contact-txt" onChange={(e) => setFieldValue("specialties", e.target.value)}>
                                            <option />
                                            {specialities.map((specialty, index) =>
                                                <option key={index}>{specialty}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="contact-form-right">
                                    <div className="contact-form-wrapper">
                                        <label>Message:</label>
                                        <textarea name="txtMessage"
                                            className={touched.txtMessage && errors.txtMessage === true ? "error" : null}
                                            onChange={handleChange}
                                            value={values.txtMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-secondary" defaultValue="Send Message" name="btn-contact" />
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


export function PatientContactUsModal(props) {
    const { show, onHide, onSubmit } = props;
    const PatientContactUsForm = useFormik({
        initialValues: {
            txtName: "",
            txtEmail: "",
            txtMessage: "",
            type: "Patient"
        },
        onSubmit: values => {
            onSubmit(values)
        },
        validate
    });
    let { handleSubmit, errors, values, handleChange, touched } = PatientContactUsForm;
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body>
                    <div className="contact-model patient">
                        <h5 className="section-title">Contact us</h5>
                        <h6 className="conatc-overview">Please feel free to contact us if you have any questions</h6>
                        <form onSubmit={handleSubmit}>
                            <div className="contact-form">
                                <div className="contact-form-left">
                                    <div className="contact-form-wrapper">
                                        <label>Name:</label>
                                        <input type="text"
                                            name="txtName"
                                            className={touched.txtName && errors.txtName === true ? "contact-txt error" : "contact-txt"}
                                            onChange={handleChange}
                                            value={values.txtName}
                                        />
                                    </div>
                                    <div className="contact-form-wrapper">
                                        <label>Email:</label>
                                        <input type="email"
                                            name="txtEmail"
                                            className={touched.txtEmail && errors.txtEmail === true ? "contact-txt error" : "contact-txt"}
                                            onChange={handleChange}
                                            value={values.txtEmail}
                                        />
                                    </div>
                                </div>
                                <div className="contact-form-right">
                                    <div className="contact-form-wrapper">
                                        <label>Message:</label>
                                        <textarea name="txtMessage"
                                            className={touched.txtMessage && errors.txtMessage === true ? "error" : null}
                                            onChange={handleChange}
                                            value={values.txtMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" className="btn" defaultValue="Send Message" name="btn-contact" />
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};