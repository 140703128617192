import React, { Component } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

class RangeSlider extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: 0
    }
  }

  componentDidMount = () => {
      this.setState({value : this.props.distance})
  }
  handleChange = value => {
    this.setState({
      value: value
    })
    this.props.setDistance(value)
  };

  render () {
    const { value } = this.state
    return (
      <div className='slider'>
        <Slider
          min={0}
          max={100}
          value={value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default RangeSlider