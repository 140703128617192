import React, { useState, useEffect } from 'react'
import { withFirebase } from '../../../services';
import history from '../../../common/history'
import like_img from '../../../assets/images/light-like-icon.png'
import message_img from '../../../assets/images/message.png'
import eye_img from '../../../assets/images/password-slow.png'
import logo from "../../../assets/images/mh-logo.png"
import moment from 'moment'
const Blog = (props) => {

    const { firebase, bID, blogData } = props;
    const [blogUserData, setBlogUserData] = useState(null);
    const [blogComments, setBlogComments] = useState(0)

    useEffect(() => {
        getBlogUserData()
        getCommentsCount()
    }, [blogData])

    const getBlogUserData = async () => {
        const result = await firebase.db.collection("users").doc(blogData?.data.userId).get();
        if (result.exists) {
            const uData = result.data();
            setBlogUserData(uData);
        }
    }

    const getCommentsCount = async () => {

        const ref = await firebase.db.collection("blog/" + bID + "/feedbacks");

        ref.onSnapshot((data) => {
            setBlogComments(data.size)
        });
    }


    return (
        <div className="col-md-4 grid-item">
            <div onClick={() => history.push(`/blog/${blogData.id}`)} className={`box ${blogData.data.isEvent && 'event'}`}>
                <div className="user-img">
                    <img src={blogUserData?.profilePhoto ? blogUserData.profilePhoto : logo} alt="" />
                    <div className="user-name">
                        <p>{blogUserData?.name}</p>
                        <span>{moment(new Date(blogData.data.creationDate.seconds * 1000).toLocaleDateString("en-US")).format('MMMM DD, YYYY')}</span>
                    </div>
                </div>
                {blogData.data.headerImage ? <div className="blog-img">
                    <img src={blogData.data.headerImage} alt="" />
                </div> : null}
                <h3>{blogData.data.title}</h3>
                <div className="blog-controler">
                    {
                        blogData.data.isEvent ? null : <>
                            <div className="view">
                                <img src={eye_img} alt="" />
                                <span>{blogData.data.viewCount}</span>
                            </div>
                            <div className="like">
                                <img src={like_img} alt="" />
                                <span>{blogData.data.likes?.length}</span>
                            </div>
                            <div className="message">
                                <img src={message_img} alt="" />
                                <span>{blogComments}</span>
                            </div></>
                    }
                </div>
            </div>
        </div>
    )
}

export default withFirebase(Blog)
