
import { getDistance } from 'geolib';

const getDistanceMiles = (providerLocation, userLocation) => {

    const location =  getDistance(
        providerLocation,
        userLocation
   );

  const meters = location / 1609.344

  return Math.round(meters)

}

export default getDistanceMiles