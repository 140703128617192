
import React, { useState } from 'react'
import down_array from '../../../assets/images/down-array.png'
import { withFirebase } from '../../../services'
import LocationMarker from '../LocationMarker'

const LocationModal = (props) => {

    const { firebase, userData, SweetAlert, setSweetAlert, setModalShow } = props
    
    const [isSaving, setIsSaving] = useState(false)
    const [location, setLocation] = useState(userData.location ? userData.location : '')

  const changeLocation = async (e) => {
    setIsSaving(true)
    e.preventDefault()
    const userId = firebase.auth.currentUser.uid
    try {
        let dataTobeSaved = { ...userData }
        dataTobeSaved.location = location
        let ref = firebase.db.collection("users").doc(userId);

        await ref.set(dataTobeSaved, { merge: true }).then(() => {
            setIsSaving(false)
            setModalShow(false)
            setSweetAlert({ 
                ...SweetAlert, 
                 show: true,
                 title: "Location Successfully Updated.", 
                 type: "success", 
                 onConfirm: () => {
                    setSweetAlert({...SweetAlert, show: false})
                 } })
        }).catch((error) => {
            setIsSaving(false)
            console.log(error)
        });
    } catch (error) {
        setIsSaving(false)
        console.log(error)
    }

  }
    return (
        <div className="location">
            <form onSubmit={changeLocation}>
                <div className="form-control locationchange-modal">
                <LocationMarker
                    value={location}
                    handleChange={(e) => {
                        setLocation(e);
                    }}
                />
                    <img src={down_array} alt="" />
                    <span className="floating-label">Location</span>
              
                </div>
                <input type="submit" id="email" name="email" value={isSaving ? "Saving..." : "Save Changes"} />
            </form>
        </div>
    )
}

export default withFirebase(LocationModal)
