import React from "react";
import logo from "../../assets/images/logo.png";
import { Loading } from "../../components/Generic";
import { HeaderMenu } from "../../components/Generic";
import NewPasswordForm from "./NewPasswordForm";

function NewPassword() {
  return (
    <>
      <Loading />
      <div id="wrapper" className="user-login forgot-login">
        <header id="header">
          <div className="container">
            <div className="inner-header">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="Logo" />
                </a>
              </div>
              <HeaderMenu />
            </div>
          </div>
        </header>
          <NewPasswordForm />
      </div>
    </>
  );
}

export default NewPassword;
