import React from 'react'
import { useEffect } from 'react';
import Pagination from "react-js-pagination";
import nextPage from "../../assets/images/nextPage.png"

function CustomPagination(props) {
    const { handlePageChange, activePage, setActivePage, Total, itemsCount, isProvider } = props;
    return (
        <div className={isProvider ? "Pagination provider-pagination" : "Pagination"}>
            {isProvider &&

                <>
                    {
                        activePage > 1 &&

                        <span className="prev-page" style={{ cursor: "pointer" }} onClick={() => {
                            handlePageChange(activePage - 1)
                        }
                        }
                        > Prev
                        </span>}
                </>
            }
            <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsCount}
                totalItemsCount={Math.ceil(Total)}
                pageRangeDisplayed={3}
                linkClassFirst={isProvider && "hidden"}
                linkClassPrev={isProvider && "hidden"}
                linkClassNext={isProvider && "hidden"}
                linkClassLast={isProvider && "hidden"}
                lastPageText={null}
                firstPageText={null}
                nextPageText={null}
                prevPageText={null}
                onChange={handlePageChange.bind(this)}
            />
            {activePage === Math.ceil(Total / itemsCount) || activePage === Math.ceil(Total / itemsCount) - 1 ? null :
                <span className={isProvider && "last-page"}>...<a onClick={() => setActivePage(Math.ceil(Total / itemsCount))}>{Math.ceil(Total / itemsCount)}</a></span>
            }
            {isProvider ?

                <>{activePage < 10 && Total > 10 ? <span className="next-page" style={{ cursor: "pointer" }} onClick={() =>
                    activePage < Math.ceil(Total / itemsCount) ?
                        handlePageChange(activePage + 1) : null
                }
                > Next
                </span> :
                    <span></span>}</>
                :
                <span style={{ cursor: "pointer" }} onClick={() =>
                    activePage < Math.ceil(Total / itemsCount) ?
                        handlePageChange(activePage + 1) : null
                }
                > <img src={nextPage} alt="" />
                </span>}
        </div>
    )
}

export default CustomPagination
