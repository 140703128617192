import { useFormik } from 'formik';
import React, { useState } from 'react'
import { withFirebase } from '../../services'
import  CustomSweetAlert  from '../../components/Generic/CustomSweetAlert'
import OtpInput from 'react-otp-input';
function NewPasswordForm() {

    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "",
        onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
        onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
    });

    const [codeInputs, setCodeInputs] = useState('')

    const NewPasswordForm = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""

        },
        onSubmit: values => {
            // onPasswordReset(values.txtEmail);
        }
    })
    const handleCodeChange = (code) => setCodeInputs(code);

    let { handleSubmit, values, handleChange, handleBlur } = NewPasswordForm;
    return (
        <div>
            <section id="section">
            <div className="login-wrapper">
                <div className="container">
                    <div className="login-block">
                        <div className="login-left">
                            <h2 className="title">New password</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-field-wrapper">
                                    <div className="form-field full">
                                        <label>Confirmation code</label>

                                        <div className="code-container">
                                                <OtpInput
                                                    value={codeInputs}
                                                    onChange={handleCodeChange}
                                                    numInputs={4}
                                                />
                                        </div>
                                    </div>
                                    <div className="form-field full">
                                        <label>Password:</label>
                                        <input
                                            type="password"
                                            name="txtPass"
                                            placeholder="Enter New Password"
                                            className="txt"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                    </div>
                                    <div className="form-field full">
                                        <label>Confirm password:</label>
                                        <input
                                            type="pasword"
                                            name="txtConfirmPass"
                                            placeholder="Enter Password Again"
                                            className="txt"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmPassword}
                                        />
                                    </div>
                                    <div className="btn-wrapper">
                                        <button type="submit" className="btn" name="btn-submit" >
                                            Confirm and Login    
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <CustomSweetAlert {...SweetAlert}/>
        </div>
    )
}

export default withFirebase(NewPasswordForm);
