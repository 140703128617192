import React, { useState } from 'react'
import advance_menu from "../../assets/images/advance-menu.png"
import close_icon from "../../assets/images/close_icon.png"
import '../../assets/css/cmpstyles.css'
import RangeSlider from './RangeSlider'
import ProviderSearch from './ProviderSearch'

export const AdvancedSearch = (props) => {

    const {
        isCategoryAvailable,
        pageTitle,
        category,
        handleSearch,
        handleAdvancedSearch,
        isSearchOpen,
        setIsSearchOpen,
        onSubmitSearch,
        activateDropdown,
        setActivateDropdown,
        openFilteredDrop,
        setOpenFilteredDrop
    } = props;
    const [name, setName] = useState("")
    const [distance, setDistance] = useState(15)
    const [isRefresh, setIsRefresh] = useState(false)
    const [services, setServices] = useState(
        [
            {
                name: "General physician",
                selected: false
            },
            {
                name: "Dentist",
                selected: false
            },
            {
                name: "Children specialist",
                selected: false
            },
            {
                name: "Chiropractor",
                selected: false
            },
            {
                name: "Skin specialist",
                selected: false
            },
            {
                name: "Internal medicine",
                selected: false
            },
            {
                name: "Cardiologist",
                selected: false
            },
            {
                name: "Eye specialist",
                selected: false
            }, {
                name: "Reproduction",
                selected: false
            },
            {
                name: "Obscetric gynecologist",
                selected: false
            },
            {
                name: "Acute / Critical Care",
                selected: false
            }, {
                name: "Allergy and Immunology",
                selected: false
            },
            {
                name: "Allied Health",
                selected: false
            },
            {
                name: "Anesthesiology",
                selected: false
            },
            {
                name: "Bariatric Medicine",
                selected: false
            }, {
                name: "Dermatology",
                selected: false
            },
            {
                name: "Dietitian",
                selected: false
            },
            {
                name: "Doula",
                selected: false
            }, {
                name: "Ear Nose and Throat",
                selected: false
            },
            {
                name: "Emergency Medicine",
                selected: false
            },
            {
                name: "Endocrinology",
                selected: false
            },
            {
                name: "Family Medicine",
                selected: false
            },
            {
                name: "Neonatology",
                selected: false
            },
            {
                name: "Neurology",
                selected: false
            },
            {
                name: "Nurce-Midwives",
                selected: false
            },
            {
                name: "Physical Therapy",
                selected: false
            },
            {
                name: "Oncology",
                selected: false
            },
            {
                name: "Occupational Therapy",
                selected: false
            },
        ]
    )
    const [selectedSpec, setSelectedSpec] = useState([])
    const [location, setLocation] = useState("")

    const [searchcat, setSearchcat] = useState("")
    const handleSelection = (i) => {
        let x = services;
        services[i].selected = !services[i].selected;
        setServices(x)
        setIsRefresh(!isRefresh)
    }
    const handleReset = () => {
        let x = services
        x.forEach(cat => {
            if (cat.selected) {
                cat.selected = false
            }
        });
        setServices(x)
        setIsRefresh(!isRefresh)
    }

    const handleSearchValue = (e) => {
        setSearchcat(e.target.value)
    }

    const hansleApplyFilters = () => {
        const selectedServices = []
        services.map(service => {
            if (service.selected) {
                selectedServices.push(service.name)
            }
        })

        handleAdvancedSearch(distance, selectedServices)

    }

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        onSubmitSearch(selectedSpec, location, name)

    }

    const clearAll = (e) => {
        e.preventDefault()       
        setSelectedSpec([])
        setName("")
        setLocation("")
        window.location.reload()
    }
    return (
        <div className={`search-section ${isCategoryAvailable ? 'category-search-section' : ''}`}>
            <div className="container">
                <h3>{pageTitle}</h3>
               {!isCategoryAvailable && <div className="find-doctor">
                    <form onSubmit={category ? null : handleSearchSubmit}>
                        <div className="form-inner">
                            {category ? <div className="searchcat-container">
                                <div className="full">
                                    <input
                                        type="text"
                                        name="txtsearch"
                                        placeholder="Type name of the category"
                                        className="txt"
                                        onChange={(e) => handleSearchValue(e)}
                                        value={searchcat}
                                    />
                                </div>
                            </div> :
                                <ProviderSearch
                                    name={name}
                                    setName={setName}
                                    selectedSpec={selectedSpec}
                                    setSelectedSpec={setSelectedSpec}
                                    location={location}
                                    setLocation={setLocation}
                                    activateDropdown={activateDropdown}
                                    setActivateDropdown={setActivateDropdown}
                                    openFilteredDrop={openFilteredDrop}
                                    setOpenFilteredDrop={setOpenFilteredDrop}
                                />
                            }
                            <div className="submit-btn">
                                {category ?
                                    <input type="submit" onClick={(e) => {
                                        e.preventDefault()
                                        handleSearch(searchcat)
                                    }} value={category ? "Find Category" : "Find Doctor"} />
                                    : <input type="submit" value="Find Doctor" />}
                            </div>
                            <div className="submit-btn">
                                <input type="button" value="Clear All" style={{marginLeft:'10px'}} onClick={clearAll} />
                            </div>
                        </div>
                    </form>
                </div>}
                {
                    !category && !isCategoryAvailable &&
                    <> <div onClick={() => setIsSearchOpen(!isSearchOpen)} className="advance-search" style={{display:"none"}}>
                        {isSearchOpen ?
                            <>
                                <img src={close_icon} alt="User" />
                                <span>Close Advanced Search</span>
                            </>
                            : <>
                                <img src={advance_menu} alt="User" />
                                <span>Advanced Search</span>
                            </>}
                    </div>

                        {isSearchOpen &&
                            <div className="search-filter">
                                <div className="inner-fliter">
                                    <div className="container">
                                        <div className="range-fliter py-4">
                                            <div className="text">
                                                <p>Distance</p>
                                                <span>+{distance}mi</span>
                                            </div>
                                            <div className="range py-4">
                                                <RangeSlider distance={distance} setDistance={setDistance} />
                                            </div>
                                        </div>
                                        <div className="btn-list">
                                            <h2>Choose type of services</h2>
                                            <ul>
                                                {
                                                    services.map((service, i) => (
                                                        <li key={i} onClick={() => handleSelection(i)} className={service.selected ? "active" : ""}>{service.name}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="fliter-btn pt-3">
                                            <ul>
                                                <li onClick={handleReset}>Reset All</li>
                                                <li className="theme-btn" onClick={hansleApplyFilters}>Apply Filters</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </>
                }
            </div>
        </div>
    )
}


export const specializations = [
    "General physician",
    "Dentist",
    "Children specialist",
    "Skin specialist",
    "Internal medicine",
    "Cardiologist",
    "Chiropractor",
    "Eye specialist",
    "Reproduction",
    "Obscetric gynecologist",
    "Acute / Critical Care",
    "Allergy and Immunology",
    "Allied Health",
    "Anesthesiology",
    "Bariatric Medicine",
    "Dermatology",
    "Dietitian",
    "Doula",
    "Physical Therapy",
    "Ear Nose and Throat",
    "Emergency Medicine",
    "Endocrinology",
    "Family Medicine",
    "Neonatology",
    "Neurology",
    "Nurce-Midwives",
    "Oncology",
    "Occupational Therapy"
]