import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

function CustomSweetAlert(props) {
    const { message } = props;
   
            return (
                <>
                    <SweetAlert
                    closeOnClickOutside={false}
                         {...props}
                    >   {message}
                    </SweetAlert>
                </>
            )

}

export default CustomSweetAlert
