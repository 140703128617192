import React, { useState, useEffect } from 'react'
import { withFirebase } from '../../../services';
import { Spinner } from 'evergreen-ui';
import { FileUploadWithURL } from '../../../common';

const CommentForm = (props) => {

    const { firebase, bID } = props;

    const [myRating, setMyRating] = useState ({
        comment : "",
        commentImage : null
    })

    const [isLoading, setLoading] = useState(false)
    const [noComment, setNoComment] = useState(false)

    const handleComment = (e) => {

        setMyRating({
            ...myRating, 
            comment : e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
      
        if(myRating.comment.length < 1){
            setNoComment(true)
        } else {
            setLoading(true)
            setNoComment(false)


            if (myRating.commentImage === null) {
    
                const data = {
                    userID : firebase.auth.currentUser.uid,
                    comment : myRating.comment,
                    commentImage: null
                }
    
                DataSubmit(data);
            } else {
                submitWithImage()
            }
    }
  }
    const submitWithImage = () => {
        let base64String = "";
        const file = myRating.commentImage
        const reader = new FileReader();
        reader.onload = function () {
            base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");

            processSubmit(reader.result)
        }
        reader.readAsDataURL(file);
        return (reader.result)
    }

    const processSubmit = (baseImg) => {

        let Data = FileUploadWithURL({ img: baseImg, path: `images`, firebase: firebase, name: firebase.auth.currentUser.uid });
        Data.then(url => {
            const data = {
                userID : firebase.auth.currentUser.uid,
                comment : myRating.comment,
                commentImage: url
            }

            DataSubmit(data);
        }).catch(e => {
            console.log(e);
        })
    }
    const handleCommentImage = (e) => {
        if(e.target.files.length > 0){
            setMyRating({ ...myRating, commentImage: e.target.files[0] })
        }
    }
    const DataSubmit = async (data) => {

        const dataTobeSaved = {...data}

        dataTobeSaved.likes = []
        dataTobeSaved.createDate = new Date();

        let ref = firebase.db.collection("blog/" + bID +  "/feedbacks").doc();
        await ref.set(dataTobeSaved, function (error) {
            if (error) {
                console.log(error);
            } else {
                console.log("this one")
            }
        });
        setLoading(false)
        document.getElementById("fileImage").value = null;
        setMyRating({
            comment : "",
            commentImage : null
        })
    }

    const removeFile = () => {
        document.getElementById("fileImage").value = null;
        setMyRating({...myRating, commentImage: null})
    }

    return (
        <form onSubmit={handleSubmit}>
            <h2 className="form-title">Your comment:</h2>
            <div className="form-inner">
                <textarea name="text" className={noComment && "no-comment"} onChange={(e) => handleComment(e)} value={myRating.comment} cols="30" rows="5" placeholder="What made you satisfied?"></textarea>
                  {myRating.commentImage && <div className="image-preview">
                       <span className="preview-span" onClick={removeFile}><h6>X</h6></span>
                        <img src={URL.createObjectURL(myRating.commentImage)} alt="" />
                    </div>}
                    <div className="file">
                        <button className="btn">Upload a file</button>
                       <input type="file" accept='image/*' id="fileImage" onChange={(e) => handleCommentImage(e)} />
                    </div>
               {isLoading ? <div className="comment-loader"><Spinner size={35} /> </div> : <input type="submit" />}
            </div>
        </form>
    )
  }


export default withFirebase(CommentForm)
