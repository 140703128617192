import React, { useState } from "react";
import { Loading } from "../../../components/Generic";
import ReferralUserForm from "./ReferralUserForm";

function ReferUser() {
  return (
    <div id="wrapper" className="refer-user">
      <section id="section">
        <div className="login-wrapper">
          <div className="container">
            <div className="login-block">
              <div className="login-left">
                <div className="referral-form">
                  <ReferralUserForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loading />
    </div>
  );
}

export default ReferUser;
