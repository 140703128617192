import React, { useState, useEffect } from 'react'
import loc_img from "../../../assets/images/loc_img.png"
import phone_icon from "../../../assets/images/phone_icon.png"
import glob_icon from "../../../assets/images/glob_icon.png"
import address_icon from "../../../assets/images/address_icon.png"
import msg_icon from "../../../assets/images/msg_icon.png"
import schedule_clock from "../../../assets/images/schedule_clock.png"
import back_arrow from "../../../assets/images/back_arrow.png"
import up_arrow from "../../../assets/images/up_arrow.png"
import logo from "../../../assets/images/doctor.png";
import './doctor.css'
import { DoctorDescription } from './DoctorDescription'
import DoctorReview from './DoctorReview'
import history from '../../../common/history'
import full_star from '../../../assets/images/full-star.svg'
import { Spinner } from 'evergreen-ui';
import sm_star from '../../../assets/images/sm-star.png'
import Rating from 'react-rating'
import { useParams } from 'react-router'
import { withFirebase } from '../../../services'
import getDistanceMiles from '../../../common/distanceMiles'

const Doctor = (props) => {

    const { firebase } = props;

    const [currentTab, setCurrentTab] = useState("description")
    const [doctorDetails, setDoctorDetails] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [distance, setDistance] = useState(0)
    const [isImgLoding, setImgLoding] = useState(true);


    let { id } = useParams();
    useEffect(() => {
        if (id) {         
            getDoctorDetails(id)
        }
    }, [id])



    const getDoctorDetails = async (id) => {
        //debugger
        setLoading(true)
        const result = await firebase.db.collection("healthCareProfessionals").doc(id).get();

        if (result.exists) {
            const Physician = result.data();
            setDoctorDetails(Physician);
            getUserLocation(Physician.location.latitude, Physician.location.longitude)
        }
    }

    const getUserLocation = async (lat, lng) => {
        navigator.geolocation.getCurrentPosition(
            position => setUserLocationData(position, lat, lng),
            err => console.log(err)
        );
    }

    const setUserLocationData = async (position, lat, lng) => {

        const providerLocation = {
            latitude: lat,
            longitude: lng
        }
        const userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
        const distanceMeters = await getDistanceMiles(providerLocation, userLocation)

        setDistance(distanceMeters)
        setLoading(false);

    }



    const changeTab = (name) => {
        setCurrentTab(name)
    }
    return (
        <div className="doctor-container">
            <div className="blue-block"></div>
            {
                isLoading ? <ul className="loader doctor-loader"> <li><Spinner size={50} /></li> </ul>
                    :

                    <div className="doctor-detailes">
                        <div onClick={() => { history.push('/providers') }} className="top-arrow">
                            <img src={back_arrow} alt="" />
                            <span>Return to search</span>
                        </div>
                        <div className="detailes-top">
                            <div className="profile-image">
                                {!isImgLoding ? null : <div className="headshot-loader"><Spinner size={35} /> </div>}
                                <img src={doctorDetails?.headshotURL ? doctorDetails.headshotURL : logo} alt="profile" onLoad={(e) => setImgLoding(false)} />
                            </div>
                            <div className="right-detailes">
                                <div className="top-data">
                                    <div className="basic-info">
                                        <h3 className="name">{doctorDetails?.name}</h3>
                                        <div className="specialties">
                                            {
                                                doctorDetails?.specialties.map((spec, i) => (
                                                    <span key={i}>{spec}</span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="loc-rating">
                                        <div className="location">
                                            <div className="loc">
                                                <img src={loc_img} alt="location" />
                                                <span>{distance}mi</span>
                                            </div>
                                        </div>
                                        {doctorDetails?.averageRating && <div className="star-div">
                                            <div className="img">
                                                <Rating
                                                    emptySymbol={<img src={sm_star} className="icon" />}
                                                    fullSymbol={<img src={full_star} className="icon" />}
                                                    initialRating={doctorDetails?.averageRating}
                                                    readonly
                                                />
                                                <span className="rev-count">{doctorDetails?.ratingCount}</span>
                                            </div>
                                        </div>}
                                    </div>

                                </div>
                                <div className="contact-info">
                                    <div className="contact-data">
                                        <h3 className="contact-title">Contact information</h3>
                                        <div className="group-data">
                                            <div className="info-field">
                                                <div><img src={phone_icon} alt="" /></div>
                                                <span className="contact-data phone-no">{doctorDetails?.phoneNumber}</span>
                                            </div>
                                            {doctorDetails?.website && <div className="info-field">
                                                <div className="back-shade"><img src={glob_icon} alt="" /></div>
                                                <span className="contact-data website">
                                                    <a target="_blank" href={"https://" + doctorDetails?.website}>{doctorDetails?.website}</a>
                                                </span>
                                            </div>}
                                            <div className="info-field">
                                                <div className="back-shade"><img src={address_icon} alt="" /></div>
                                                <span className="contact-data address">{doctorDetails?.address}</span>
                                            </div>
                                            <div className="info-field" hidden={doctorDetails?.teleHealthApplication == undefined}>
                                                <div className="back-shade"><img src={msg_icon} alt="" /></div>
                                                <a className="contact-data address" href={doctorDetails?.teleHealthApplication === 'kareo' ? 'https://practice.kareo.com/melenatedhealthcare' : 'https://open.epic.com/Scheduling/HL7v2'}>Schedule appointment in {doctorDetails?.teleHealthApplication}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule">
                                        <div className="icon-clock back-shade">
                                            <img src={schedule_clock} alt="clock" />
                                        </div>
                                        <div className="timings-container">
                                            <div className="timings-top">
                                                <h3 className="open-text">Open</h3>
                                                <h3>Close in 5:00pm</h3>
                                                <img src={up_arrow} alt="up-arrow" />
                                            </div>
                                            <hr />
                                            <div className="days">
                                                <div className="day">
                                                    <span>Monday</span>
                                                    <span className="time">9:00am - 5:00pm</span>
                                                </div>
                                                <div className="day">
                                                    <span>Tuesday</span>
                                                    <span className="time">9:00am - 5:00pm</span>
                                                </div>
                                                <div className="day">
                                                    <span>Wednesday</span>
                                                    <span className="time">9:00am - 5:00pm</span>
                                                </div>
                                                <div className="day active">
                                                    <span>Thursday</span>
                                                    <span className="time">9:00am - 5:00pm</span>
                                                </div>
                                                <div className="day">
                                                    <span>Saturday</span>
                                                    <span className="time">Weekend</span>
                                                </div>
                                                <div className="day">
                                                    <span>Sunday</span>
                                                    <span className="time">Weekend</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="detailes-bottom">
                            <div className="tabs-container">
                                <span onClick={() => changeTab("description")} className={currentTab === "description" ? 'active' : "span"}>Description</span>
                                <span onClick={() => changeTab("review")} className={currentTab === "review" ? 'active' : "span"}>Reviews</span>
                            </div>
                            <div className="tab-view">
                                {
                                    currentTab === "description" ? <DoctorDescription data={doctorDetails} /> : <DoctorReview data={doctorDetails} docID={id} />
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default withFirebase(Doctor)