import React from 'react'
import './style.css'


const SwitchButton = ({checkStatus, name, handleChange}) => {


 
    return (
        <div className="switch-container">
        <label>
            <input name={name} checked={checkStatus} onChange={(e) => handleChange(e)} className="switch" type="checkbox" />
            <div>
                <div></div>
            </div>
        </label>
    </div>
    )
}

export default SwitchButton
