import React, { useState, useEffect } from 'react'
import review_like from '../../assets/images/like.png'
import liked_thumb from '../../assets/images/liked_thumb.png'
import sm_star from '../../assets/images/sm-star.png'
import full_star from '../../assets/images/full-star.svg'
import Rating from 'react-rating'
import { withFirebase } from '../../services'
import moment from 'moment'
import logo from "../../assets/images/logo.png";
const Review = (props) => {

    const { review, firebase, docID, doctorReview } = props;
    const [reviewerData, setReviewerData] = useState(null)
    const [isLiked, setIsLiked] = useState(false)
    const [userRating, setUserRating] = useState(0)

    useEffect(() => {
           //debugger
           //console.log(review.like)
           if(review.like != undefined || review.Review != null){
            if(review.likes.length > 0){
                checkLike()
            }
           }
       

    }, [isLiked, review])

    useEffect(() => {
        getReviewerData()
    }, [review, isLiked])

    const checkLike = async () => {

            const liked = await review.likes.includes(firebase.auth.currentUser.uid)

            if (liked) {
                setIsLiked(true)
            } else {
                setIsLiked(false)
            }
        
    }
    const getReviewerData = async () => {
        //debugger
        const result = await firebase.db.collection("users").doc(review?.userID).get();
        if (result.exists) {
            const uData = result.data();
            setReviewerData(uData);
        } else {
            console.log("not found")
        }
    }

    const handleLike = async () => {
    
        let dataTobeSaved = { ...review }
        if (dataTobeSaved.likes.length > 0) {

            if (isLiked) {
                setIsLiked(false)
                const refLikes = []
                dataTobeSaved.likes.map(like => {
                    if (like !== firebase.auth.currentUser.uid) {
                        refLikes.push(firebase.auth.currentUser.uid)
                    }
                })

                dataTobeSaved.likes = refLikes
            } else {
                dataTobeSaved.likes.push(firebase.auth.currentUser.uid)
            }
        } else {
            dataTobeSaved.likes.push(firebase.auth.currentUser.uid)
        }

        let ref = doctorReview ? firebase.db.collection("healthCareProfessionals/" + docID + "/feedbacks").doc(review.id) : firebase.db.collection("blog/" + docID + "/feedbacks").doc(review.id) 

        await ref.set(dataTobeSaved, { merge: true }).then(() => {
        }).catch((error) => console.log(error));
        setIsLiked(!isLiked)
    }

    return (
        <li>
            <div className="content-inner">
                <div className="img">
                    <img src={reviewerData?.profilePhoto ? reviewerData.profilePhoto : logo} alt="profile" />
                </div>
                <div className="text">
                    <span>{reviewerData?.name}</span>
                    {review.rating && <div className="star">
                        <Rating
                            emptySymbol={<img src={sm_star} className="icon" />}
                            fullSymbol={<img src={full_star} className="icon" />}
                            placeholderSymbol={<img src={sm_star} className="icon" />}
                            initialRating={review.rating}
                            readonly
                        />
                    </div>}

                </div>
                <div className="right-date">
                    <span>{doctorReview ? moment(new Date(review?.updateDate.seconds * 1000).toLocaleDateString("en-US")).format('MMM DD') : moment(new Date(review?.createDate.seconds * 1000).toLocaleDateString("en-US")).format('MMM DD')}</span>
                </div>
            </div>
            <div className="bottom">
                <p>{review?.comment}</p>
                {review.commentImage && <div className="content-img">
                    <img src={review.commentImage} alt="" />
                </div>}
                <div className="like">
                    <img onClick={handleLike} src={isLiked ? liked_thumb : review_like} alt="" />
                    <span>{review?.likes?.length}</span>
                </div>
            </div>
        </li>
    )
}

export default withFirebase(Review)
