import React from 'react'
import Modal from 'react-bootstrap/Modal'
import EmailModal from './EmailModal'
import LocationModal from './LocationModal'
import NameModal from './NameModal'
import PasswordModal from './PasswordModal'
export const UserModal = (props) => {

    const { show, setModalShow, name, userData ,SweetAlert, setSweetAlert, reload } = props

    return (
        <Modal className="modal-container" show={show} onHide={() => reload()}>
            <Modal.Header closeButton>
            {name === "email" &&
                <h1>change details</h1>
            }
            { name === "password" &&
                <h1>change {name}</h1>
            }
            { name === "username" &&
                <h1>change {name}</h1>
            }
            { name === "location" &&
                <h1>change {name}</h1>
            }
            </Modal.Header>
            <Modal.Body>
                {name === "email" &&
                   <EmailModal setModalShow={setModalShow} userData={userData} SweetAlert={SweetAlert} setSweetAlert={setSweetAlert} />
                }

                {
                    name === "password" &&
                     <PasswordModal />
                }
                {
                    name === "username" &&
                    <NameModal setModalShow={setModalShow} userData={userData} SweetAlert={SweetAlert} setSweetAlert={setSweetAlert} />
                }
                {
                    name === "location" &&
                     <LocationModal userData={userData} SweetAlert={SweetAlert} setSweetAlert={setSweetAlert} setModalShow={setModalShow} />
                }
            </Modal.Body>

        </Modal>
    )
}
