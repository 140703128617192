import React, { useState, useEffect } from 'react'
import "./style.css"
import BlogList from './BlogList'
import { withFirebase } from '../../../services'
import { Spinner } from 'evergreen-ui';

function Blogs(props) {

    
    const { firebase } = props;

    const [sortBy, setSortBy] = useState("popularity")
    const [showCat, setShowCat] = useState("all")
    const [searchQuery, setSearchQuery] = useState("")
    const [searchedBlogs, setSearchedBlogs] = useState(null)
    const [blogs, setBlogs] = useState(null)
    const [allblogs, setAllBlogs] = useState(null)
    const [filteredBlogs, setFilteredBlogs] = useState(null)
    const [onlyfilteredBlogs, setOnlyFilteredBlogs] = useState(null)
    const [noData, setNoData] = useState(false)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getBlogs()
    }, [])

    const getBlogs = async () => {

        setLoading(true)

        let ref = await firebase.db.collection("blog").orderBy("likes", "desc");
        ref.onSnapshot(function (querySnapshot) {
            let blogsData = []
            querySnapshot.forEach(function (doc) {
                blogsData = blogsData.concat({
                    id: doc.id,
                    data: doc.data()
                });
            });
            setAllBlogs(blogsData)
            setBlogs(blogsData);
            setLoading(false);
        });
    }

    const handleSortChange = (name) => {
        setSortBy(name)

         if(name === "new") {
            const sortedBlogs = filteredBlogs ? filteredBlogs : blogs
            sortedBlogs.sort((a,b) => (a.data.creationDate < b.data.creationDate) ? 1 : ((b.data.creationDate < a.data.creationDate) ? -1 : 0))
             
            if(filteredBlogs) {
                setFilteredBlogs(sortedBlogs)
            } else {
                setBlogs(sortedBlogs)
            }
         } else {
             const sortedBlogs = filteredBlogs ? filteredBlogs : blogs

             sortedBlogs.sort((a,b) => (a.data.likes.length < b.data.likes.length) ? 1 : ((b.data.likes.length < a.data.likes.length) ? -1 : 0))
            
             if(filteredBlogs){
                 setFilteredBlogs(onlyfilteredBlogs)
             } else {
                 setBlogs(allblogs)
             }
         }
    }
    const handleShowChange = (name) => {
        setShowCat(name)

        if(name === "only articles") {
            const filterBlogs =  blogs.filter(blog => {
                if(blog.data.hasOwnProperty('isEvent')){
                    if(blog.data.isEvent === false){
                        return blog
                    }
                } else {
                    return blog
                }
            })
            setFilteredBlogs(filterBlogs)
            setOnlyFilteredBlogs(filterBlogs)
        } else if (name === "only events") {
            const filterBlogs =  blogs.filter(blog => blog.data.isEvent === true)
            setFilteredBlogs(filterBlogs)
            setOnlyFilteredBlogs(filterBlogs)

        } else {
            setFilteredBlogs(null)
            setOnlyFilteredBlogs(allblogs)
        }
    }

    const handleSearch = (e) => {
        setLoading(true)
        e.preventDefault()
        if(searchQuery.length > 0) {
            let tempBlogst = filteredBlogs ? filteredBlogs : blogs
            let tempBlogs = tempBlogst.filter(blog => {
                return  blog.data.title.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
            })
            setSearchedBlogs(tempBlogs)
            if(tempBlogs.length < 1){
                setLoading(false)
                setNoData(true)
            } else {
               
                setNoData(false)
                setLoading(false)
            }

        } else {
            setSearchedBlogs(null)
            setNoData(false)
            setLoading(false)
        }
    }
    return (
        <div className="blogs-container">
                <section className="blog-sec py-5">
                     <div className="container">
                        <h2>Blog</h2>
                        <div className="row align-items-center py-3 main-search-filter">
                            <div className="col-md-5">
                                <div className="find-doctor">
                                    <form onSubmit={(e) => handleSearch(e)}> 
                                        <div className="form-inner">
                                            <input type="text" name="search-blog" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search"/>
                                            <input type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-7 blog-filter-row">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="sort-by">
                                            <label>Sort by: </label>
                                            <span onClick={() => handleSortChange("popularity")} className={sortBy === "popularity" ? "active" : ""}>popularity</span>
                                            <span onClick={() => handleSortChange("new")} className={sortBy === "new" ? "active" : ""}>new</span>
                                        </div>
                                        <div className="sort-by-mob">
                                            <label>Sort by: </label>
                                            <select>
                                                <option value="popularity" onClick={() => handleSortChange("popularity")}>popularity</option>
                                                <option value="new" onClick={() => handleSortChange("new")}>new</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="sort-by">
                                            <label>Show : </label>
                                            <span onClick={() => handleShowChange("all")} className={showCat === "all" ? "active" : ""}>all</span>
                                            <span onClick={() => handleShowChange("only articles")} className={showCat === "only articles" ? "active" : ""}>only articles</span>
                                            <span onClick={() => handleShowChange("only events")} className={showCat === "only events" ? "active" : ""}>only events</span>
                                        </div> 
                                        <div className="sort-by-mob">
                                            <label>show: </label>
                                            <select onChange={(e) => handleShowChange(e.target.value)}>
                                                <option value="all">all</option>
                                                <option value="only articles">only articles</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </section>
                <section className="blog-list">
                    <div className="container">
                          {isLoading ? <div className="comment-loader"><Spinner size={35} /> </div> :
                          
                          <BlogList blogs={ searchedBlogs ? searchedBlogs : filteredBlogs ? filteredBlogs : blogs }/>}
                          {
                              noData && <h4 className="no-data">No blogs found</h4>
                          }
                    </div>
                </section>
        </div>
    )
}

export default withFirebase(Blogs)