import React, { useState } from 'react'
import password_show from '../../../assets/images/password-slow.png'
import { withFirebase } from '../../../services'

const PasswordModal = (props) => {
    
    const { firebase } = props
    const [passwordType, setPasswordType] = useState(true)
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const changePassword = async (e) => {
        e.preventDefault()
    }

    return (
        <div className="password">
            <form onSubmit={changePassword}>
                <div className="form-control">
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="inputText" required />
                    <span className="floating-label">Current password</span>
                </div>
                <div className="form-control">
                    <input type={passwordType ? "password" : "text"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="inputText" required />
                    <img onClick={() => setPasswordType(!passwordType)} src={password_show} alt="" />
                    <span className="floating-label">New password</span>
                </div>
                <input type="submit" id="email" name="email" value="Save Changes" />
            </form>
        </div>
    )
}

export default withFirebase(PasswordModal)
