import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCxWcR_dzxUjZ6O6J3wghHjvaloJBp8_Do",
    authDomain: "melanatedhealthc.firebaseapp.com",
    databaseURL: "https://melanatedhealthc.firebaseio.com",
    projectId: "melanatedhealthc",
    storageBucket: "melanatedhealthc.appspot.com",
    messagingSenderId: "611486537983",
    appId: "1:611486537983:web:2f86816d72519a426854ca",
    measurementId: "G-CZ8TN6JRNV"
};

class Firebase {
    authUser;
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.db = app.firestore();
        this.firestore = app.firestore;
        this.storage = app.storage();
        this.functions = app.functions();
        this.facebookProvider = new app.auth.FacebookAuthProvider()
    }

    dosignIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  

    doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

    dosignUp = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((user) => {
            if (user) {
                this.isUserAdmin(user).then((data) => {
                    if (data !== false) { return next({ ...user, ...data }) }
                    else { return fallback(); }
                })
            } else {
                return fallback();
            }
        });

        onAuthNormalUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((user) => {
            if (user) {

                return next(user)
                // this.isUserAdmin(user).then((data) => {
                //     if (data !== false) { return next({ ...user, ...data }) }
                //     else { return fallback(); }
                // })
            } else {
                return fallback();
            }
        });

    dosignOut = async () => await this.auth.signOut();

    isUserAdmin = async (user) => {
        let isAdmin = false;
        await this.db.doc(`users/${user.uid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    if (doc.data().role === "admin") {
                        isAdmin = doc.data();
                    } else {
                        isAdmin = false;
                    }
                } else {
                    isAdmin = false;
                }
            });
        return isAdmin;
    }

};

export class FirebaseApp {
    constructor() {
        var app2 = app.apps.length !== 2 ? app.initializeApp(firebaseConfig, "Secondary") : app.app();
        this.auth = app2.auth();
        this.db = app2.firestore();
        this.storage = app2.storage();
        this.functions = app2.functions();
    }
}

export default Firebase;