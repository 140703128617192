export const FileUpload = async(props) => {
    const { path, img, firebase } = props
    var storage = firebase.storage;
    const imgName = new Date().getTime() +"_"+ img.name;
    const uploadTask = storage.ref(path + "/" + imgName).put(img);
    let uploadPromise = new Promise((resolve, reject) => {
    uploadTask.on(
        "state_changed",
        snapshot => {
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
        },
        error => {
            reject(error);
        },
        () => {
            storage.ref(path + "/")
                .child(imgName)
                .getDownloadURL()
                .then(url => {
                    resolve(url);
                });
        }
    ); 
});
    return uploadPromise;
}

export const FileUploadWithURL = async(props) => {
    const { path, img, firebase,name } = props
    var storage = firebase.storage;
    const imgName = new Date().getTime() +"_"+ name +".png";
    const uploadTask = storage.ref(path + "/" + imgName).putString(img,"data_url")
    let uploadPromise = new Promise((resolve, reject) => {
    uploadTask.on(
        "state_changed",
        snapshot => {
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
        },
        error => {
            reject(error);
        },
        () => {
            storage.ref(path + "/")
                .child(imgName)
                .getDownloadURL()
                .then(url => {
                    resolve(url);
                });
        }
    ); 
});
    return uploadPromise;
}

