import { Alert } from "evergreen-ui";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../services";
import { CustomSweetAlert} from '../../../components/Generic';

function ReferralUserForm(props) {
  const { firebase } = props;

  const [SweetAlert, setSweetAlert] = useState({
    show: false,
    type: "info",
    title: "",
    onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }) },
    onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
  });

  const getReferral = [];

  useEffect(() => {
    getReferralData()
  }, [])

  const [referral, setReferral] = useState(getReferral);

  const getReferralData = () => {
    debugger;
    var getProviderData = firebase.db.collection("referrals");
    getProviderData.onSnapshot((querySnapshot) =>{
      querySnapshot.forEach((doc) => {
      getReferral.push(doc.data().providerEmail);
      });
    })
  }
  
  console.log(referral);

  const ReferralForm = useFormik({
    initialValues: {
      providerEmail: "",
      firstName: "",
      lastName:"",
      mobileNumber: ""
    },
    onSubmit: (values) => {
      console.log(values);
      setReferralData(values);
    },
  });

  const setReferralData = (data) => {
    debugger;
    const emailRegex = /\S+@\S+\.\S+/;
    const mobileNumberRegex = /^[0-9]{5,15}$/;

    if(data.firstName == ""){
      showMessageBox("error","Please enter first name");
      return;
    }
    else if(data.providerEmail == "" || !emailRegex.test(data.providerEmail)){
      showMessageBox("error","Please enter valid email address");
      return;
    }
    else if(data.mobileNumber == "" || !mobileNumberRegex.test(data.mobileNumber)){
      showMessageBox("error","Please enter valid mobile number");
      return;
    }

    if(referral.includes(data.providerEmail)){
      showMessageBox("info","Provider email already referred");
      return;
    }

    const loggedInEmail = firebase.auth.currentUser.email;
    const dbSet = firebase.db;
    dbSet.collection("referrals").add({
      providerEmail: data.providerEmail,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNumber: data.mobileNumber,
      referredByUser: loggedInEmail,
      isEmailSent: "false",
      hasProviderLoggedIn: "false",
    });

    const mailData = {
      "email": data.providerEmail
  }

    const referralProviderMail = firebase.functions.httpsCallable('sendProviderReferEmail');

    try {

      referralProviderMail(mailData).then(function (result) {

            console.log(result);
            setSweetAlert({
              show: true,
              type: "success",
              message: "Data submitted successfully and sending referrals",
              onConfirm: () => {
                  setSweetAlert({ ...setSweetAlert, show: false })
                  window.history.back();
              },
              onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
            })
        });

    } catch (error) {
        console.log(error);
        showMessageBox("error","Error Occured");
    }
  };

  const showMessageBox = (type,message) =>{
    setSweetAlert({
      show: true,
      type: type,
      message: message,
      onConfirm: () => {
          setSweetAlert({ ...setSweetAlert, show: false })
      }
    })
  }

  let { handleSubmit, values, handleChange, errors, touched } = ReferralForm;
  return (
      <form onSubmit={handleSubmit}>
        <h2 className="title">Refer Provider</h2>
        <div className="form-field-wrapper">
        <div className="form-field" style={{marginBottom:30}}>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"              
              placeholder="First Name"
              className="txt"
              onChange={handleChange}
              value={values.firstName}
            />
          </div>
          <div className="form-field" style={{marginBottom:30}}>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="txt"
              onChange={handleChange}
              value={values.lastName}
            />
          </div>

          <div className="form-field" style={{marginBottom:30}}>
            <label>Provider Email:</label>
            <input
              type="text"
              name="providerEmail"
              placeholder="youremail@example.com"
              className="txt"
              onChange={handleChange}
              value={values.providerEmail}
            />
          </div>
          <div className="form-field" style={{marginBottom:30}}>
            <label>Provider Mobile Number</label>
            <input
              type="number"
              name="mobileNumber"
              placeholder="Mobile Number"
              className="txt"
              minLength={5}
              maxLength={15}
              onChange={handleChange}
              value={values.mobileNumber}
            />
          </div>
          <div className="btn-wrapper">
            <input
              type="submit"
              className="btn"
              value="Refer Provider"
              name="btn-submit"
              style={{marginBottom:25}}
            />
          </div>
          <CustomSweetAlert {...SweetAlert} />
        </div>
      </form>
  );
}

export default withFirebase(ReferralUserForm);
