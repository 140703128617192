import React, { useState, useEffect } from 'react'
import { withFirebase } from '../../services'
import { Spinner } from 'evergreen-ui';
import Comment from './Comment'
const MyComments = (props) => {

    const { firebase } = props

    const [commentsData, setCommentsData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    useEffect(() => {

        getData()

    }, [])

    const getData = async () => {
        setIsLoading(true)
        const result = await firebase.db.collection("users/" + firebase.auth.currentUser.uid + "/comments");

        result.onSnapshot(function (querySnapshot) {
            let comments = []
            querySnapshot.forEach(function (doc) {
                comments.push(doc.data())
            });

            if(comments.length < 1){
                setIsLoading(false)
                setNoData(true)
            } else {
                getComments(comments)
            }
        });

    }



    const getComments = (data) => {

        const getDoctorDetails = async (id) => {
            const result1 = await firebase.db.collection("healthCareProfessionals").doc(id).get();
            if (result1.exists) {
                const doctor = result1.data();
                return({
                    name:doctor.name,
                    headshotURL:doctor.headshotURL,
                    popularCategory:doctor.popularCategory
                })
            }
        }

        let NX = []
        data.map(async (d, i) => {
            if (d.commentPath.length > 0) {
                const result = await firebase.db.collection(d.commentPath).doc(d.commentId);
                result.onSnapshot(async(doc) => {
                    let commentData = doc.data()
                    await getDoctorDetails(d.docID).then((data)=>{ 
                        let Y = {...data,...commentData}                      
                        NX = NX.concat(Y)
                        setCommentsData(NX)                        
                    })                    
                });
            }
            setIsLoading(false)
        })
    }

    return (
        <div className="tab-view user-comments-list">
            <ul>
                {
                    isLoading ? <li className="user-comments-loader"><Spinner size={50} /></li> :

                        <>
                        { noData ? <h4 className="no-data">There is no comments available</h4> :
                            <>
                            {commentsData?.map((comment, i) => (
                                   <Comment key={i} comment={comment} />
                            ))
                            }
                            </>
                        }
                        </>
                }
            </ul>
        </div>
    )
}

export default withFirebase(MyComments)
