import React, { useState, useEffect } from 'react'
import { withFirebase } from '../../../services'
import CommentForm from './CommentForm'
import { Spinner } from 'evergreen-ui';
import Review from '../../../components/Generic/Review'
const DoctorReview = (props) => {

    const { docID, data, firebase } = props;

    const [reviewData, setReviewData] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [totalReviews, setTotalReviews] = useState(0)

    useEffect(() => {
        getReviewData()
    }, [])

    const getReviewData = async () => {
        setLoading(true)
        const ref = await firebase.db.collection("healthCareProfessionals/" + docID +  "/feedbacks");

        ref.onSnapshot((data) => {
            setTotalReviews(data.size)
            let feedbacks = []
            data.forEach(function (doc) {
                feedbacks = feedbacks.concat({ id: doc.id, ...doc.data() })
            });

            setReviewData(feedbacks)
            setLoading(false)
        });

    }

    return (
        <div className="reviews">
            <div className="reviews-inner">
                <div className="left">
                    <ul className="rating">
                        <li>
                            <span>{data.averageRating ? data.averageRating : 0}</span>
                            <p>Rating</p>
                        </li>
                        <li>
                            <span>{totalReviews}</span>
                            <p>Number of reviews</p>
                        </li>
                        <li>
                            <span>80</span>
                            <p>Recommendations</p>
                        </li>
                    </ul>
                    <ul className="reviews-content">
                        {
                            isLoading ? <li className="review-loader"><Spinner size={50} /></li> :  

                           <> {
                                reviewData?.map((review, i) => (
                                    <Review doctorReview={true} key={i} review={review} docID={docID} />
                                ))
                            } </>
                        }
                    </ul>
                </div>
                <CommentForm docID={docID} />
            </div>
        </div>
    )
}

export default withFirebase(DoctorReview)