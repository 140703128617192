import React, { useState } from 'react'
import color_star from '../../../assets/images/color-star.png'
import full_star from '../../../assets/images/full-star.svg'
import Rating from 'react-rating'
import { withFirebase } from '../../../services'
import { FileUploadWithURL } from '../../../common'
import { Spinner } from 'evergreen-ui';

const CommentForm = (props) => {

    const { firebase , docID } = props

    const [myRating, setMyRating] = useState ({
        rating : 1,
        comment : "",
        commentImage : null
    })

    const [isLoading, setLoading] = useState(false)
    const [noComment, setNoComment] = useState(false)
    const handleComment = (e) => {
        const value = e.target.value
        setMyRating({ ...myRating, [e.target.name]: value })
    }
    const handleRat = (value) => {
        setMyRating({ ...myRating, rating: value })
    }
    const handleCommentImage = (e) => {
        if(e.target.files.length > 0){
            setMyRating({ ...myRating, commentImage: e.target.files[0] })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(myRating.comment.length < 1){
            setNoComment(true)
        } else {
            setNoComment(false)
            setLoading(true)

            if (myRating.commentImage === null) {
    
                const data = {
                    rating : myRating.rating,
                    userID : firebase.auth.currentUser.uid,
                    comment : myRating.comment,
                    commentImage: null
                }
    
                DataSubmit(data);
            } else {
                submitWithImage()
            }
        }
       
    }

    const submitWithImage = () => {
        let base64String = "";
        const file = myRating.commentImage
        const reader = new FileReader();
        reader.onload = function () {
            base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");

            processSubmit(reader.result)
        }
        reader.readAsDataURL(file);
        return (reader.result)
    }

    const processSubmit = (baseImg) => {

        let Data = FileUploadWithURL({ img: baseImg, path: `images`, firebase: firebase, name: firebase.auth.currentUser.uid });
        Data.then(url => {
        
            const data = {
                rating : myRating.rating,
                userID : firebase.auth.currentUser.uid,
                comment : myRating.comment,
                commentImage: url
            }

            DataSubmit(data);
        }).catch(e => {
            console.log(e);
        })
    }

    const DataSubmit = async (data) => {

        const dataTobeSaved = {...data}

        dataTobeSaved.likes = []
        dataTobeSaved.updateDate = new Date();

        let ref = firebase.db.collection("healthCareProfessionals/" + docID +  "/feedbacks").doc();

        dataTobeSaved.id = ref.id
      await ref.set(dataTobeSaved, function (error) {
            if (error) {
                console.log(error);
            } else {
                console.log("this one")
            }
        });

        addUserComment(ref.id)


        setLoading(false)
        document.getElementById("fileImage").value = null;
        setMyRating({
            rating : 1,
            comment : "",
            commentImage : null
        })
    }

    const addUserComment = async (commentId) => {

        const commentDataTobeSaved = {
            commentPath : `healthCareProfessionals/${docID}/feedbacks`,
            commentId : commentId,
            docID: docID
        }
        let updateUserCommentData = firebase.db.collection("users/" + firebase.auth.currentUser.uid +  "/comments").doc(commentId);
        
      try {
            
        await updateUserCommentData.set(commentDataTobeSaved, function (error) {
            if (error) {
                console.log(error);
            } else {
                console.log("this one")
            }
        });
      } catch (error) {
          console.log(error)
      }
    }

    const removeFile = () => {
        document.getElementById("fileImage").value = null;
        setMyRating({...myRating, commentImage: null})
    }

    return (

        <div className="right">
            <div className="star">
                <h4>What’s your rate?</h4>
                <div className="star-inner">
                    <Rating
                        emptySymbol={<img src={color_star} className="icon" />}
                        fullSymbol={<img src={full_star} className="icon" />}
                        name="rating"
                        initialRating={myRating.rating}
                        onChange={(e) => handleRat(e)}
                    />
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <h2 className="form-title">Your comment:</h2>
                <div className="form-inner">
                    <textarea name="comment" className={noComment && "no-comment"} onChange={(e) => handleComment(e)} value={myRating.comment} cols="28" rows="5" placeholder="What made you satisfied?"></textarea>
                    {myRating.commentImage && <div className="image-preview">
                       <span onClick={removeFile}><h6>X</h6></span>
                        <img src={URL.createObjectURL(myRating.commentImage)} alt="" />
                    </div>}
                    <div className="file">
                        <button className="btn">Upload a file</button>
                       <input type="file" accept='image/*' id="fileImage" onChange={(e) => handleCommentImage(e)} />
                    </div>
                    {isLoading ? <div className="comment-loader"><Spinner size={35} /> </div> : <input type="submit" />}
                </div>
            </form>
        </div>

    )
}

export default withFirebase(CommentForm)
