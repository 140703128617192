
import React, { useState } from 'react'
import time_search from "../../assets/images/time-search.png"
import locationImg from "../../assets/images/location-icon.png"
import LocationMarker from './LocationMarker'
import { specializations } from './AdvancedSearch'
const ProviderSearch = (props) => {

    const { selectedSpec, name, setName, setSelectedSpec, location, setLocation, activateDropdown, setActivateDropdown, openFilteredDrop, setOpenFilteredDrop } = props

    const [filteredDrop, setFilteredDrop] = useState([])
    const [isRefresh, setIsRefresh] = useState(false)
    const handleSelectChange = (e) => {

        const value = e.target.value
        
        setName(value)
        let x = selectedSpec

        if (selectedSpec.length > 0) {
            if (selectedSpec.includes(value)) {
                let y = []
                x.map(data => {
                    if (data !== value) {
                        y.push(data)
                    }
                })
                x = y
            } else {
                x.push(value)
            }
        } else {
            x.push(value)
        }

        setSelectedSpec(x)
    }
    const handleInputChange = (e) => {
        const value = e.target.value
        setName(value)

        let x = specializations.filter(spec => {
            return spec.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })

        if (x.length > 0) {

            setFilteredDrop(x)
            setOpenFilteredDrop(true)
        } else {
            setOpenFilteredDrop(false)
        }
    }

    const handleSelectedSpecChange = (e) => {
        if (e.keyCode === 8) {
            if (selectedSpec.length === 1) {
                setOpenFilteredDrop(false)
            }
            let x = selectedSpec
            x.pop();
            setSelectedSpec(x)
            setIsRefresh(!isRefresh)
            setName("")
        }
    }
    return (
        <>

            <div className="select box1">
                <img src={time_search} alt="User" className="time-user" />

                {
                    selectedSpec.length > 0 ?
                        <input type="text" value={selectedSpec.map((spec) => (spec)).join(', ')}

                            onKeyDown={(e) => handleSelectedSpecChange(e)} placeholder="Type specialization or doctor’s name" /> :

                        <input type="text" value={name} onChange={(e) => handleInputChange(e)} placeholder="Type specialization or doctor’s name" />
                }


                {activateDropdown || openFilteredDrop ?
                    <>

                        <div className="drop-down-list">

                            <select class="select" value={selectedSpec} onChange={(e) => handleSelectChange(e)} multiple>
                                {
                                    filteredDrop.length > 0 ? filteredDrop.map((spec, i) => (
                                        <option key={i} value={spec} selected={selectedSpec.includes(spec) ? true : false}>{spec}</option>
                                    )) : specializations.map((spec, i) => (
                                        <option key={i} value={spec} selected={selectedSpec.includes(spec) ? true : false}>{spec}</option>
                                    ))
                                }

                            </select>
                        </div></> : null}
                    
                    <span onClick={() => {
                        setOpenFilteredDrop(false)
                        setActivateDropdown(!activateDropdown)
                    }}>
                    <i className={activateDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true" />
                    </span>
            </div>
            <div className="select box2">
                <img src={locationImg} alt="User" className="location" />
                <LocationMarker
                    value={location}
                    handleChange={(e) => {
                        setLocation(e);
                    }}
                />
            </div>
        </>
    )
}

export default ProviderSearch
