export const PhysicianDelete = async ({ imgurl, id, firebase }) => {
    if (imgurl === null) {
        await firebase.db.collection("healthCareProfessionals").doc(id).delete();
    } else {
        await firebase.storage.refFromURL(imgurl).delete().then(async function () {
            await firebase.db.collection("healthCareProfessionals").doc(id).delete();
        }).catch(function (error) {
            console.log("error", error)
        });
    }
}

export const PopularCategory = [
    "General Practitioner",
    "Dentist",
    "Doula",
    "Skin Specialist",
    "Children Specialist",
    "Internal Medicine",
    "Mental Health",
    "Obstetrician Gynecologist",
    "Cardiologist",
    "Eye Specialist ",
    "Reproduction ",
    "Neurology",
    "Ear Nose Throat",
    "Nutritionist",
];

export const specialitiesList = [
    "Acute/Critical Care",
    "Allergy and Immunology",
    "Anesthesiology",
    "Audiology",
    "Cardiology",
    "Chiropractor",
    "Dentistry",
    "Dermatology",
    "Radiology",
    "Endocrinology",
    "Emergency Medicine",
    "Ear Nose and Throat",
    "Family Medicine / Primary Care",
    "Gastroenterology",
    "Surgery",
    "Hematology",
    "Hospice and Palliative Medicine",
    "Internal Medicine",
    "Infectious Disease",
    "Medical Genetics",
    "Neonatology",
    "Neurology",
    "Nephrology",
    "Nuclear Medicine",
    "Obstetrics and Gynecology",
    "Occupational Therapy",
    "Ophthalmology",
    "Optometry",
    "Orthopedics",
    "Pathology",
    "Pain Medicine",
    "Pharmacology",
    "Pediatrics",
    "Physical Medicine and Rehabilitation",
    "Physical therapy",
    "Plastic Surgery",
    "Psychiatry / Mental Health",
    "Pulmonology",
    "Podiatry",
    "Rheumatology",
    "Sleep Medicine",
    "Sports Medicine",
    "Surgery",
    "Oncology",
    "Urology",
    "Veterinary Medicine",
    "Allied Health",
    "Bariatric Medicine"
]