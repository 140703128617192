import React, { useState, useEffect } from 'react'
import logo from "../../../assets/images/mh-logo.png"
import notification from "../../../assets/images/notification.png"
import mh_help from "../../../assets/images/mh-help.png"
import mh_user from "../../../assets/images/logo.png"
import '../../../assets/css/cmpstyles.css'
import { withFirebase } from '../../../services'


const Header = (props) => {

    const { firebase} = props;
    const [user, setUser] = useState(null)
    const [currentpath, setCurrentpath] = useState('')
    const [openLogout, setOpenLogout] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    
    useEffect(() => {
        getUsersData()
        setPath()
    }, [])

    const doLogout = () => {
        firebase.dosignOut();
    }

    const setPath = () => {
        const path = document.location.pathname.replace('/', '');
        setCurrentpath(path)
    }
    const getUsersData = async () => {
        const uid = firebase.auth.currentUser.uid

        const result = await firebase.db.collection("users").doc(uid);

        result.onSnapshot((doc) => {
            const data = doc.data()
            setUser(data);
        });

        firebase.isUserAdmin(firebase.auth.currentUser).then((data) => {
            if (data !== false) { setIsAdmin(true)}
            else {
                setIsAdmin(false)
            }
        })

    }

    const onAdminClick = () => {

    }

    return (
        <header className="mh-header">
            <div className="container">
                <div className="row header-row">
                    <div className="left-inner">
                        <div className="logo">
                            <a href="/providers"><img src={logo} alt="Logo" /></a>
                        </div>
                        <div onClick={() => setShowMenu(!showMenu)} className="hemburger">
                         {showMenu ? <i class="fa fa-times"></i> : <i className="fa fa-bars"></i>}
                        </div>
                    </div>
                    <div className="mh-menu">
                            <ul>
                                <li className={currentpath === 'providers' && `active`}>
                                    <a href="/providers">Providers</a>
                                </li>
                                <li className={currentpath === 'popular-categories' && `active`}>
                                            <a href="/providers/?virtualVisit">Telehealth</a>
                                        </li>
                                <li className={currentpath === 'popular-categories' && `active`}>
                                    <a href="/popular-categories">Popular Categories</a>
                                </li>
                                <li className={currentpath === 'blog' && `active`}>
                                    <a href="/blog">Blog</a>
                                </li>
                                <li className={currentpath === 'referral-user' && `active`}>
                                    <a href="/referral-user">Refer Provider</a>
                                </li>
                            </ul>
                        </div>
                      {showMenu && <div className="mh-menu-mob">
                            <ul>
                                <li className={currentpath === 'providers' && `active`}>
                                    <a href="/providers">Providers</a>
                                </li>
                                <li className={currentpath === 'popular-categories' && `active`}>
                                            <a href="/providers/?virtualVisit">Telehealth</a>
                                        </li>
                                <li className={currentpath === 'popular-categories' && `active`}>
                                    <a href="/popular-categories">Popular Categories</a>
                                </li>
                                <li className={currentpath === 'blog' && `active`}>
                                    <a href="/blog">Blog</a>
                                </li>
                                <li className={currentpath === 'referral-user' && `active`}>
                                    <a href="/referral-user">Refer Provider</a>
                                </li>
                            </ul>
                        </div>}


                    <div className="right-inner">
                        {/* <div className="icon">
                            <div className="notification">
                                <a href={false} className="notification-link">
                                    <span></span>
                                    <img src={notification} alt="Notification" />
                                </a>
                            </div>
                            <div className="mh-help">
                                <a href={false}><img src={mh_help} alt="Help" /></a>
                            </div>
                        </div> */}
                        <div className="mh-user">
                            <div className="user-box head-user-newblock">
                                <a href="/user-profile" className="user-link">
                                    <div className="img">
                                        <img src={user?.profilePhoto ? user?.profilePhoto : mh_user} alt="User" />
                                    </div>
                                    <div className="text">
                                        <b className={currentpath === 'user-profile' && `active-user-profile`}>{user?.name}</b>
                                        {user?.location && <span className="head-location">{user.location}</span>}
                                    </div>
                                </a>
                                {openLogout && <div className="logout-dropdown-menu">
                                    <ul>
                                       {isAdmin && <li><a href="/dashboard/list">Admin Panel</a></li>}
                                        <li><a href={false} onClick={doLogout}>Logout</a></li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        <span className="down-logout" onClick={() => setOpenLogout(!openLogout)}>
                            <i className={openLogout ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true" />
                        </span>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default withFirebase(Header)