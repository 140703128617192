import React, { Suspense } from 'react';
import { history } from './common';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import Firebase, { FirebaseContext } from './services';
import { Loading } from './components/Generic';

function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Suspense fallback={<Loading/>}>
        <Router history={history}>
          <Routes />
        </Router>
      </Suspense>
    </FirebaseContext.Provider>
  );
}

export default App;
