import React, { useState } from 'react'

//img
import location from "../../assets/images/location.png";
import edit from "../../assets/images/edit.png"
import deleteimg from "../../assets/images/delete.png"
import check from "../../assets/images/check.png"
import { useEffect } from 'react';
import { withFirebase } from '../../services';
import { PhysicianDelete, PopularCategory, specialitiesList } from './functions';
import { FileUploadWithURL, history } from '../../common';
import { CustomSweetAlert, PhotoUploadModal } from '../../components/Generic';
import { Pane, Spinner } from 'evergreen-ui';
import logo from "../../assets/images/logo.png";

function PhysiciansProfile(props) {
    const { firebase } = props
    const [userId, setUserId] = useState(null);
    const [isLoding, setLoding] = useState(false);
    const [PhysicianData, setPhysicianData] = useState(null);
    const [categorie, setCategorie] = useState(null);
    const [specialtie, setSpecialtie] = useState(null);
    const [PhotoModal, setPhotoModal] = useState(false);
    const [newPhoto, setnewPhoto] = useState(null);
    const [isChanges, setisChanges] = useState(false);
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "info",
        title: "",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }) },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });

    const [isEdit, setEdit] = useState({
        specialties: false,
        expertise: false,
        license: false,
        npiNumber: false,
        profile: false,
        categorie: false,
        mobNumber: false
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get("id");
        getPhysicianData(id);
        setUserId(id)
    }, [])

    const getPhysicianData = async (id) => {
        setLoding(true);
        const result = await firebase.db.collection("healthCareProfessionals").doc(id).get();
        if (result.exists) {
            const Physician = result.data();
            setSpecialtie(Physician.specialties[0]);
            setCategorie(Physician.specialties[1]);
            setPhysicianData(Physician);
            setLoding(false);
        }
    }

    const onEdit = (type) => {
        setEdit({ ...isEdit, [type]: !isEdit[type] });
    }

    const onDelete = async () => {
        setSweetAlert({
            show: true,
            showCancel: true,
            type: "danger",
            message: "Do you really want delete these data ?",
            onConfirm: () => {
                PhysicianDelete({ imgurl: PhysicianData.headshotURL, id: userId, firebase: firebase })
                history.push("/dashboard/list");
                setSweetAlert({ ...setSweetAlert, show: false })
            },
            onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
        })
    }
    const onSubmit = async () => {
        try {
            let dataTobeSaved = { ...PhysicianData }
            dataTobeSaved.specialties = [specialtie]
            dataTobeSaved.popularCategory = categorie !== undefined ? categorie : "";

            let ref = firebase.db.collection("healthCareProfessionals").doc(userId);

            if (isChanges) {
                let Data = FileUploadWithURL({ img: dataTobeSaved.headshotURL, path: `images`, firebase: firebase, name: dataTobeSaved.name });
                await Data.then(url => {
                    dataTobeSaved.headshotURL = url;
                }).catch(e => {
                    console.log(e);
                })
            }

            await ref.set(dataTobeSaved, { merge: true }).then(() => {
                setSweetAlert({ ...SweetAlert, show: true, title: "Profile Successfully Updated.", type: "success", onConfirm: () => { history.push("/dashboard/list"); } })
            }).catch((error) => console.log(error));
        } catch (error) {
            console.log(error)
        }
    }

    const isPhotoModal = (e) => {
        setnewPhoto(e.currentTarget.files[0]);
        setPhotoModal(true);
    }

    const PhotoToSave = (dataUrl) => {
        if (dataUrl) {
            setPhysicianData({ ...PhysicianData, headshotURL: dataUrl });
            setisChanges(true);
            setPhotoModal(false);
        } else {
            setisChanges(false);
            // setPhysicianData({ ...PhysicianData, headshotURL: null });
        }
    }
    return (
        <section id="section">
            <div className="container">
                <div className="openone-title">
                    <h2 className="title"><span> Providers / </span>Profile information</h2>
                </div>

                <div className="profile-wrapper">
                    {isLoding ?
                        <Pane display="flex" alignItems="center" justifyContent="center" width={"100%"} height={400}>
                            <Spinner size={50} />
                        </Pane>
                        :
                        <>
                            <div className="profile-left">
                                <span className="profile-edit">
                                    <a href={false} onClick={() => { onEdit("profile"); }} className="edit-btn">
                                        {isEdit.profile ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                </span>
                                <div className="physician-img">
                                    {isEdit.profile ?
                                        <> <input type="file" name="" accept='image/*' id="" className="img_upload"
                                            onChange={isPhotoModal}
                                        />
                                            <div className="overlay">
                                                <i className="fa fa-edit"></i>
                                            </div>
                                            <PhotoUploadModal
                                                show={PhotoModal}
                                                onHide={() => setPhotoModal(false)}
                                                img={newPhoto} onSave={PhotoToSave} />
                                        </> : null}
                                    <img src={PhysicianData ? PhysicianData.headshotURL
                                        ? PhysicianData.headshotURL : logo : logo} alt="" style={{ height: 125, width: 125 }} />
                                </div>

                                {!isEdit.profile ?
                                    <>
                                        <h3 className="doctor-name">
                                            {PhysicianData ? PhysicianData.title + " " + PhysicianData.name : ""}
                                        </h3>
                                        <h3 className="doctor-email">
                                            {PhysicianData ? PhysicianData.email : ""}
                                        </h3>
                                        <div className="doctor-address">
                                            <img src={location} alt="Location Image" />
                                            <p>{PhysicianData ? PhysicianData.address : ""}</p>
                                        </div>
                                    </> :
                                    <>
                                        <h3 className="doctor-name">
                                            <input type="text" value={PhysicianData.title}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, title: e.target.value })}
                                            />
                                            <input type="text" value={PhysicianData.name}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, name: e.target.value })}
                                            />
                                        </h3>
                                        <h3 className="doctor-email">
                                            <input type="email" value={PhysicianData.email}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, email: e.target.value })}
                                            />
                                        </h3>
                                        <div className="doctor-address">
                                            <input type="email" value={PhysicianData.address}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, address: e.target.value })}
                                            />
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="profile-right">
                                <ul>
                                    <li>Specialty</li>
                                    <li>
                                        {PhysicianData ?
                                            isEdit.specialties ?
                                                <select name="" id="" onChange={(e) => setSpecialtie(e.target.value)}>
                                                    <option disabled selected> Select any Specialtie </option>
                                                    {specialitiesList.map((Specialtie, index) => {
                                                        return <option key={index}
                                                            selected={specialtie === Specialtie ? "selected" : ""}
                                                        >{Specialtie}</option>
                                                    })}
                                                </select>
                                                :
                                                specialtie : ""}

                                    </li>

                                    <li><a href={false} onClick={() => { onEdit("specialties"); }} className="edit-btn">
                                        {isEdit.specialties ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>Area of Expertise</li>
                                    <li>
                                        {PhysicianData ?
                                            isEdit.expertise ?
                                                <input type="text" value={PhysicianData.expertise}
                                                    onChange={(e) => setPhysicianData({ ...PhysicianData, expertise: e.target.value })}
                                                />
                                                :
                                                PhysicianData.expertise : ""}
                                    </li>
                                    <li><a href={false} onClick={() => { onEdit("expertise"); }} className="edit-btn">
                                        {isEdit.expertise ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>License Number</li>
                                    <li>
                                        {PhysicianData ?
                                            isEdit.license ?
                                                <input type="text" value={PhysicianData.licenseNumber}
                                                    onChange={(e) => setPhysicianData({ ...PhysicianData, licenseNumber: e.target.value })}
                                                />
                                                :
                                                PhysicianData.licenseNumber : ""}
                                    </li>
                                    <li><a href={false} onClick={() => { onEdit("license"); }} className="edit-btn">
                                        {isEdit.license ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>NPI Number</li>
                                    <li>  {PhysicianData ?
                                        isEdit.npiNumber ?
                                            <input type="text" value={PhysicianData.npiNumber}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, npiNumber: e.target.value })}
                                            />
                                            :
                                            PhysicianData.npiNumber : ""}
                                    </li>
                                    <li><a href={false} onClick={() => { onEdit("npiNumber"); }} className="edit-btn">
                                        {isEdit.npiNumber ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>Popular Category</li>
                                    <li>
                                        {isEdit.categorie ?
                                            <select name="" id="" onChange={(e) => setCategorie(e.target.value)}>
                                                <option disabled selected> Select any Category </option>
                                                {PopularCategory.map((Category, index) => {
                                                    return <option key={index}
                                                        selected={categorie === Category ? "selected" : ""}
                                                    >{Category}</option>
                                                })}
                                            </select>
                                            : !categorie ? "None" : categorie}

                                    </li>
                                    <li><a href={false} onClick={() => { onEdit("categorie"); }} className="edit-btn">
                                        {isEdit.categorie ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>Phone Number</li>
                                    <li>  {PhysicianData ?
                                        isEdit.mobNumber ?
                                            <input type="text" value={PhysicianData.phoneNumber}
                                                onChange={(e) => setPhysicianData({ ...PhysicianData, phoneNumber: e.target.value })}
                                            />
                                            :
                                            PhysicianData.phoneNumber : ""}
                                    </li>
                                    <li><a href={false} onClick={() => { onEdit("mobNumber"); }} className="edit-btn">
                                        {isEdit.mobNumber ? <i className="fa fa-check"></i> : <img src={edit} alt="" />}
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </>}
                </div>
                <div className="profile-btn-wrapper">
                    <button className="btn-del" onClick={onDelete}><span><img src={deleteimg} /></span><span> Delete Profile</span></button>
                    <button className="btn-save" onClick={onSubmit}><span><img src={check} /></span> <span>Save Changes</span></button>
                </div>
            </div>
            <CustomSweetAlert {...SweetAlert} />
        </section>
    )
}

export default withFirebase(PhysiciansProfile);
