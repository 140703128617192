import React, { useState, useEffect } from 'react'
import back_array from '../../../assets/images/back-array.png'
import user_img from '../../../assets/images/user.png'
import single_blog_img from '../../../assets/images/single-blog.png'
import like_img from '../../../assets/images/white-like-icon.png'
import message_img from '../../../assets/images/white-message.png'
import eye_img from '../../../assets/images/white-password-slow.png'
import large_like from '../../../assets/images/large-like.png'
import large_message from '../../../assets/images/large-message.png'
import { useParams } from 'react-router-dom';
import history from '../../../common/history'
import moment from 'moment'
import liked_thumb from '../../../assets/images/liked_thumb.png'
import { withFirebase } from '../../../services'
import BlogReview from './BlogReview'
import logo from "../../../assets/images/logo.png"



const BlogArticle = (props) => {

    const { firebase } = props;
    const [blogData, setBlogData] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [blogUserData, setBlogUserData] = useState(null);
    const [commentsCount, setCommentsCount] = useState(0)
    const [reviewData, setReviewData] = useState(null)
    const [isLiked, setIsLiked] = useState(false)

    let { id } = useParams();

    useEffect(() => {
       getBlogData()
    }, [])

    const getBlogData = async () => {
            setLoading(true)

         const result = await firebase.db.collection("blog").doc(id);

            result.onSnapshot((doc) => {
                let blog = doc.data()
                setBlogData(blog);
                getReviewData()
                if(blog.likes?.length > 0){
                    checkLike(blog)
                }
                getBlogUserData(blog.userId);
            });

    }

    const checkLike = async (blog) => {

        const liked = await blog?.likes.includes(firebase.auth.currentUser.uid)

        if (liked) {
            setIsLiked(true)
        } else {
            setIsLiked(false)
        }
    
}
   
    const getReviewData = async () => {

        const ref = await firebase.db.collection("blog/" + id + "/feedbacks");

        ref.onSnapshot((data) => {
            setCommentsCount(data.size)
            let feedbacks = []
            data.forEach(function (doc) {
                feedbacks = feedbacks.concat({ id: doc.id, ...doc.data() })
            });
            setReviewData(feedbacks)
        });

    }
        
    const getBlogUserData = async (userID) => {
        const result = await firebase.db.collection("users").doc(userID).get();
        if (result.exists) {
            const uData = result.data();
            setBlogUserData(uData);
        }
        setLoading(false);
    }

    const handleLike = async () => {
        let dataTobeSaved = { ...blogData }
        if (dataTobeSaved.likes?.length > 0) {
            if (isLiked) {
                setIsLiked(false)
                const refLikes = []
                dataTobeSaved.likes.map(like => {
                    if (like !== firebase.auth.currentUser.uid) {
                        refLikes.push(firebase.auth.currentUser.uid)
                    }
                })

                dataTobeSaved.likes = refLikes
            } else {
                dataTobeSaved.likes.push(firebase.auth.currentUser.uid)
            }
        } else {
            dataTobeSaved.likes.push(firebase.auth.currentUser.uid)
        }

        let ref = firebase.db.collection("blog/").doc(id) 

        await ref.set(dataTobeSaved, { merge: true }).then(() => {
        }).catch((error) => console.log(error));
        setIsLiked(!isLiked)
    }

    
    return (
        <div className="article-container">
            <section className="single-blog-head py-5">
                <div className="container">
                    <div onClick={() => {history.push('/blog')}} className="back-text">
                        <img src={back_array} alt="" />
                        <span>Return to blog</span>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-9">
                            <h2>{blogData?.title}</h2>
                            <div className="btn-list py-4">
                                <ul>
                                    {
                                        blogData?.blogTags?.map((tag, i) => (
                                            <li key={i}>{tag}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 article-head">
                            <div className="user-active pb-3">
                                <img src={blogUserData?.profilePhoto ? blogUserData?.profilePhoto : logo } alt="" />
                                <div className="user-text">
                                    <span>{blogUserData?.name}</span>
                                    <p>{moment(new Date(blogData?.creationDate.seconds * 1000).toLocaleDateString("en-US")).format('MMMM D, YYYY')}</p>
                                </div>
                            </div>
                            <div className="blog-controler">
                                <div className="view">
                                    <img src={eye_img} alt="" />
                                    <span>{blogData?.viewCount}</span>
                                </div>
                                <div className="like">
                                    <img src={like_img} alt="" />
                                    <span>{blogData?.likes?.length}</span>
                                </div>
                                <div className="message">
                                    <img src={message_img} alt="" />
                                    <span>{commentsCount}</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <section className="single-blog-content">
                <div className="container">
                   {blogData?.headerImage ?  <div className="single-img">
                        <img src={blogData?.headerImage} alt="" />
                    </div> : null }
                   {blogData?.isEvent && <div className="event-active">
                        <div className="text">
                            <h2>{moment(new Date(blogData?.eventDate?.seconds * 1000).toLocaleDateString("en-US")).format('ddd, MMMM Do, YYYY')}</h2>
                            <label>Duration: <span>{moment(new Date(blogData?.eventStartTime?.seconds * 1000).toLocaleDateString("en-US")).format('h:mm a')} - {moment(new Date(blogData?.eventEndTime?.seconds * 1000).toLocaleDateString("en-US")).format('h:mm a')}</span></label>
                            <label>Members: <span>{blogData?.eventTotalMembers ? blogData.eventTotalMembers : 2}</span></label>
                        </div>
                        <div className="right-btn">
                            <a href={false} className="theme-btn"> Register</a>
                            <a href={false}  className="light-btn">Add to calendar</a>
                        </div>
                    </div>}
                    <div className="blog-content" dangerouslySetInnerHTML={{ __html: blogData?.blogContent }}>
                    </div>
                   {!blogData?.isEvent && <div className="single-bottom-btn pb-5">
                        <ul>
                            <li>
                                <div onClick={handleLike}>
                                    <img src={isLiked ? liked_thumb : large_like} alt="" />
                                    <span>Like</span>
                                </div> 
                            </li>
                            <li>
                                <div>
                                    <img src={large_message} alt="" />
                                    <span>Comment</span>
                                </div>  
                            </li>  
                        </ul>
                    </div>}
                </div>
            </section>
            {!blogData?.isEvent && <section className="reviews_sec">
                <div className="reviews container">
                    <BlogReview bID={id} reviewData={reviewData}/>
                </div>
            </section>
              }
       
       </div>
    )
}


export default withFirebase(BlogArticle)