import React, { useState, useEffect } from 'react'
import { withFirebase } from '../../../services';
import Blog from './Blog';

const BlogList = (props) => {

    const { firebase, blogs } = props;

    const [isLoading, setLoading] = useState(false)
 

    return (
        <div className="row">
      { blogs?.map((blog, i) => (

          <Blog key={i} bID={blog.id} blogData={blog} />
           
        ))} 

        </div>
    )
}

export default withFirebase(BlogList)
