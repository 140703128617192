import React, { useEffect, useState } from 'react';
import Header from './Header';
import { withFirebase } from '../../../services';
import history from '../../../common/history'
import { AuthUserContext } from '../../../Context';
import { Loading } from '../../Generic';

function NewDashboardLayout(props) {
    const { children, firebase } = props;
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (user === null) {
            firebase.onAuthNormalUserListener(
                (user) => {
                    if (user) {
                        setUser(user);
                    }
                },
                () => {
                    history.push("/user-login");
                }
            );}
    }, [user])

    return (
        <>
            {user ?
                <AuthUserContext.Provider value={user}>
                    <div className="wrapper" authUser={user}>
                        <Header user={user}/>
                        {children}
                    </div>
                </AuthUserContext.Provider>
                :
                <Loading />
            }
        </>
    )
}

export default withFirebase(NewDashboardLayout);