import React, { useState } from 'react'
import { withFirebase } from '../../../services'

const EmailModal = (props) => {
    const { firebase, userData, SweetAlert, setSweetAlert, setModalShow, onExit, onHide } = props
    const [email, setEmail] = useState(userData.email)
    let[ ethnicityVal, setEthnicity] = useState( userData.ethinicity);
    let ageGroupVal = userData.ageGroup;
    let genderVal = userData.gender;
    var ethnicitySelected = "";
    var ethnicityOtherSelected = ethnicity.indexOf(ethnicityVal) < 0 ? ethnicityVal : "";
    const [isSaving, setIsSaving] = useState(false)

    if(ethnicity.indexOf(ethnicityVal) < 0){
        console.log(userData);
        ethnicityVal = "Other";
        ethnicityOtherSelected = userData.ethnicity;
    }

    const setFieldValue = async(field, valueProp) =>{
        if(field == "ethnicity"){
            ethnicityVal = valueProp
            userData.ethinicity = valueProp
            setEthnicity("ethnicity", valueProp)
            console.log(ethnicityVal)

            debugger;
            if(document.getElementById("dvEthnicityOther") != null){
                if(ethnicityVal == "Other"){
                    document.getElementById("dvEthnicityOther").style.display = "block"
                }else{
                    document.getElementById("dvEthnicityOther").style.display = "none"
                }
            }
        }

        if(field == "ageGroup"){
            ageGroupVal = valueProp
        }

        if(field == "gender"){
            genderVal = valueProp
        }
    }

    const changeEmail = async (e) => {
        genderVal = document.getElementById("selGender").value
        ageGroupVal = document.getElementById("selAgeGroup").value
        ethnicityVal = document.getElementById("selEthnicity").value
        if(genderVal == null || genderVal == ""){
            // setSweetAlert({
            //     show: true,
            //     type: "error",
            //     message: "Enter valid gender",
            //     onConfirm: () => {
            //         setSweetAlert({ ...setSweetAlert, show: false })
            //     },
            // })
            // e.preventDefault()
            // return;

            genderVal = ""
        }
        
        if(ageGroupVal == null || ageGroupVal == ""){
            // setSweetAlert({
            //     show: true,
            //     type: "error",
            //     message: "Enter valid age group",
            //     onConfirm: () => {
            //         setSweetAlert({ ...setSweetAlert, show: false })
            //     },
            // })
            // e.preventDefault()
            // return;

            ageGroupVal = ""
        }        
        
        if(ethnicityVal == null || ethnicityVal == ""){
            // setSweetAlert({
            //     show: true,
            //     type: "error",
            //     message: "Enter valid ethnicity",
            //     onConfirm: () => {
            //         setSweetAlert({ ...setSweetAlert, show: false })
            //     },
            // })
            // e.preventDefault()
            // return;

            ethnicityVal = ""
        }

        if(ethnicityVal == "Other")
        {
            ethnicityOtherSelected = document.getElementById("ethnicityOtherInp").value;
        
            if( ethnicityOtherSelected == null || ethnicityOtherSelected == ""){
                // setSweetAlert({
                //     show: true,
                //     type: "error",
                //     message: "Enter valid ethnicity",
                //     onConfirm: () => {
                //         setSweetAlert({ ...setSweetAlert, show: false })
                //     },
                // })
                // e.preventDefault()
                // return;

                ethnicityOtherSelected = ""
            }
        }



        setIsSaving(true)
        e.preventDefault()
        const userId = firebase.auth.currentUser.uid
       
        // firebase.functions.useFunctionsEmulator("http://localhost:5001");

        const updateUserDetails = firebase.functions.httpsCallable('updateUserDetails');
        await updateUserDetails({ uid: userId, email: email }).then(async (data) => {
            try {
                ethnicitySelected = document.getElementById("selEthnicity").value
                console.log(ethnicitySelected)
                let dataTobeSaved = { ...userData }
                dataTobeSaved.email = email
                dataTobeSaved.ageGroup = ageGroupVal
                dataTobeSaved.gender = genderVal
                dataTobeSaved.ethinicity = ethnicitySelected == "Other" ? ethnicityOtherSelected : ethnicitySelected
                let ref = firebase.db.collection("users").doc(userId);
    
                await ref.set(dataTobeSaved, { merge: true }).then(() => {
                    setIsSaving(false)
                    setModalShow(false)
                    setSweetAlert({
                        ...SweetAlert,
                        show: true,
                        title: "Details Successfully Updated.",
                        type: "success",
                        onConfirm: () => {
                            setSweetAlert({ ...SweetAlert, show: false })
                        }
                    })
                }).catch((error) => {
                    setIsSaving(false)
                    console.log(error)
                });
            } catch (error) {
                setIsSaving(false)
                console.log(error)
            }


        }).catch((error) =>{
            setIsSaving(false)
             console.log(error)
            })
    }

    return (
        <div className="email">
            <form onSubmit={changeEmail}>
                <div className="form-control">
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="inputText" required />
                    <span className="floating-label">Email</span>
                </div>
                <div className={"form-field"}>
                    <label>Gender:</label>
                    <select defaultValue={genderVal} id="selGender" onChange={(e) => setFieldValue("gender", e.target.value)}>
                        <option />
                        {gender.map((gender, index) =>
                            <option key={index}>{gender}</option>
                        )}
                    </select>
                </div>
                <div className={"form-field"}>
                    <label>Age Group:</label>
                    <select defaultValue={ageGroupVal} id="selAgeGroup" onChange={(e) => setFieldValue("ageGroup", e.target.value)}>
                        <option />
                        {ageGroup.map((ageGroup, index) =>
                            <option key={index}>{ageGroup}</option>
                        )}
                    </select>
                </div>
                <div className={"form-field"}>
                    <label>Ethnicity:</label>
                    <select defaultValue={ethnicityVal} id="selEthnicity" onChange={(e) => setFieldValue("ethnicity", e.target.value)}>
                        <option />
                        {ethnicity.map((ethnicity, index) =>
                            <option key={index}>{ethnicity}</option>
                        )}
                    </select>
                </div>
                <br/>
                <div className={"form-field"}>
                <div id='dvEthnicityOther' className={"form-field"} style={{display:ethnicity.indexOf(userData.ethinicity) < 0 ? 'block' : 'none' }}>
                        <input type="text" defaultValue={ethnicity.indexOf(userData.ethinicity) < 0 ? userData.ethinicity: ethnicityOtherSelected} name="ethnicityOther" className="txt " 
                                id='ethnicityOtherInp' placeholder="Enter Ethnicity" 
                            />
                    </div>
                </div>
                <input type="submit" id="email" name="email" value={isSaving ? "Saving..." : "Save Changes"} />
            </form>
        </div>
        
    )
}

export const ageGroup = [
    "18-24","25-34","35-44","45-54","55-64","65 and over"
]

export const ethnicity = [
    "American Indian or Alaska Native","Asian","Black or African American","Hispanic or Latino","Native Hawaiian or Other Pacific Islander","White","Two or more","Other"
]

export const gender = [
    "Male","Female","Transgender","Non-Binary","Gender Neutral"
]

export default withFirebase(EmailModal)

