import React, { useState, useEffect } from 'react'
import SwitchButton from '../../components/Generic/switch/SwitchButton'
import { withFirebase } from '../../services'

const NotificationsTab = (props) => {

    const { userData, firebase } = props

    const [notificationCheckes, setNotificationCheckes] = useState({
        emails : false,
        likes: false,
        comments: false
    })

    useEffect(() => {
        setNotifications()
    }, [])

    const setNotifications = () => {

        if(userData.notifications){
            setNotificationCheckes({
                emails : userData.notifications.emails,
                likes: userData.notifications.likes,
                comments : userData.notifications.comments,
            })
        } else {
            setNotificationCollection()
        }
    }

    const setNotificationCollection = async () => {

        let dataTobeSaved = { ...userData }
        dataTobeSaved.notifications = notificationCheckes

        let ref = firebase.db.collection("users").doc(firebase.auth.currentUser.uid);

       await ref.set(dataTobeSaved, function (error) {
            if (error) {
                console.log(error);
            } else {
                console.log("this one")
            }
        });

    }

    const handleChange = (e) => {

        setNotificationCheckes({...notificationCheckes, [e.target.name] : !notificationCheckes[e.target.name]})

        let tempNotification = {...notificationCheckes}
        tempNotification[e.target.name] = !userData.notifications[e.target.name]
        const userId = firebase.auth.currentUser.uid
        try {
            let dataTobeSaved = { ...userData }
            dataTobeSaved.notifications = tempNotification
            let ref = firebase.db.collection("users").doc(userId);

            ref.set(dataTobeSaved, { merge: true }).then(() => {
            }).catch((error) => {
                console.log(error)
            });
        } catch (error) {
            console.log(error)
        }


    }

    return (
        <div className="tab-view notification">
        <ul>
            <li>
                <SwitchButton name="emails" handleChange={handleChange} checkStatus={notificationCheckes.emails} notificationCheckes={notificationCheckes}  setNotificationCheckes={setNotificationCheckes}/>
                <span>Email notifications</span>
            </li>
            <li>
                <SwitchButton name="likes" handleChange={handleChange} checkStatus={notificationCheckes.likes} notificationCheckes={notificationCheckes} setNotificationCheckes={setNotificationCheckes}/>
                <span>Likes</span>
            </li>
            <li>
                <SwitchButton name="comments" handleChange={handleChange} checkStatus={notificationCheckes.comments} notificationCheckes={notificationCheckes} setNotificationCheckes={setNotificationCheckes}/>
                <span>New Comments</span>
            </li> 
        </ul>
    </div>
    )
}

export default withFirebase(NotificationsTab)
