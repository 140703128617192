import React, { useState } from 'react'
import full_star from '../../assets/images/full-star.svg'
import sm_star from '../../assets/images/sm-star.png'
import Rating from 'react-rating'
import list_like from "../../assets/images/like1.png"
import logo from "../../assets/images/mh-logo.png"
import { Spinner } from 'evergreen-ui';

import moment from 'moment'
const Comment = (props) => {
    const { comment } = props

    const [isImgLoding, setImgLoding] = useState(true);

    return (
        <li>
            <div className="list-inner">
                <div className="img">
                { !isImgLoding ? null : <div className="headshot-loader"><Spinner size={35} /> </div> }
                    <img src={comment?.headshotURL ? comment.headshotURL : logo} alt="User" onLoad={(e)=> setImgLoding(false)} />
                </div>
                <div className="text">

                    <h5>{comment?.name}</h5>
                    {comment?.popularCategory && <p>{comment?.popularCategory}</p>}
                </div>
                <div className="list-icon">
                    <div className="like">
                        <img src={list_like} alt="User" /><span>{comment?.likes.length}</span>
                    </div>
                    <div className="star">
                        <Rating
                            emptySymbol={<img src={sm_star} className="icon" />}
                            fullSymbol={<img src={full_star} className="icon" />}
                            initialRating={comment?.rating}
                            readonly
                        />
                    </div>
                    <div className="date">
                        <p>{moment(new Date(comment?.updateDate?.seconds * 1000).toLocaleDateString("en-US")).format('MMM DD')}</p>
                    </div>
                </div>
            </div>
            <p>{comment?.comment}</p>
        </li>
    )
}

export default Comment
