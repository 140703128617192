import cat_icon1 from "../../../assets/images/icon/cat1.png"
import cat_icon2 from "../../../assets/images/icon/cat2.png"
import cat_icon3 from "../../../assets/images/icon/cat3.png"
import cat_icon4 from "../../../assets/images/icon/cat4.png"
import cat_icon5 from "../../../assets/images/icon/cat5.png"
import cat_icon6 from "../../../assets/images/icon/cat6.png"
import cat_icon7 from "../../../assets/images/icon/cat7.png"
import cat_icon8 from "../../../assets/images/icon/cat8.png"
import cat_icon9 from "../../../assets/images/icon/cat_icon9.png"
import cat_icon10 from "../../../assets/images/icon/cat_icon10.png"
import cat_icon11 from "../../../assets/images/icon/cat_icon11.png"
import cat_icon12 from "../../../assets/images/icon/cat_icon12.png"
import cat_iconMental from "../../../assets/images/icon/v3.svg"

export const categoriesData = [
    {   
        id: "1",
        accessor: "general-physician",
        image : cat_icon1, 
        title : "General Physician"
    },
    {   
        id: 2,
        accessor: "skin-specialist",
        image : cat_icon3, 
        title : "Skin Specialist"
    },
     {   
        id: 3,
        accessor: "dentist",
        image : cat_icon2, 
        title : "Dentist"
    },
    {   
        id: 4,
        accessor: "children-specialist",
        image : cat_icon4, 
        title : "Children Specialist"
    },
    {   
        id: 5,
        accessor: "internal-medicine",
        image : cat_icon5, 
        title : "Internal Medicine"
    },
    {   
        id: 6,
        accessor: "eye-specialist",
        image: cat_icon7,
        title: "Eye Specialist"
    },
    {   
        id: 7,
        accessor: "obstetric-gynecologist",
        image: cat_icon6,
        title: "Obstetric Gynecologist"
    },
    {   
        id: 8,
        accessor: "ear-nose-throat",
        image: cat_icon9,
        title: "Ear Nose Throat"
    },
    {   
        id: 9,
        accessor: "reproduction",
        image: cat_icon10,
        title: "Reproduction"
    },
    {  
         id: 10,
        accessor: "doula",
        image: cat_icon11,
        title: "Doula"
    },
    {   
        id: 11,
        accessor: "neurology",
        image: cat_icon12,
        title: "Neurology"
    },
    {   
        id: 12,
        accessor: "cardiologist",
        image: cat_icon8,
        title: "Cardiologist"
    },
    {
        id: 13,
        accessor: "mental-health",
        image: cat_iconMental,
        title: "Mental Health",
    }
]
