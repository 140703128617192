import React from 'react'

export const CategoryCard = ({image, title, handleClick}) => { 

    return (
        <li>
        <a href={false} onClick={handleClick} className="icon-link">
            <img src={image} alt="User" />
            <span>{title}</span>
         </a>
       </li>
    )
}
