import React, { useState, useEffect } from 'react'
import { AdvancedSearch } from '../../../components/Generic/AdvancedSearch'
import "./style.css"
import { CategoryCard } from './CategoryCard'
import { categoriesData } from './data'
import { history } from '../../../common'

function Categories() {

    const [searchCat, setSearchCat] = useState("")
    const [categories, setCategories] = useState([])

    useEffect(() => {

        setCategories(categoriesData)
      
    }, [])

    const handleSearch = (value) => {

        let x = categoriesData.filter(cat => {
            return  cat.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
        
        setCategories(x)
    }

 const handleClick = (id) => {
        history.push(`/providers/${id}`)
    }
    return (
        <div className="popular-categories1">

            <AdvancedSearch pageTitle="Popular Categories" handleSearch={handleSearch} searchCat={searchCat} setSearchCat={setSearchCat} category />
            <div className="all-categories">
                <div className="row">
                    <div className="logo-list">
                        {!categories === null || categories.length > 0 ? <ul>
                            {
                                categories.map((cat, i) => (
                                    <CategoryCard key={i} image={cat.image} title={cat.title} handleClick={() => handleClick(cat.accessor)} />
                                ))
                            }
                        </ul> : <h1>No such category found...</h1>}
                       
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Categories
