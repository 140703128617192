import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { RouteWithLayout } from "../components";
import DashboardLayout from "../components/Layout/DashboardLayout";
import NewDashboardLayout from "../components/Layout/NewDashboardLayout";
import PhysiciansProfile from "../views/Dashboard/PhysiciansProfile";
import { Providers } from "../views/DashboardNew";
import BlogArticle from "../views/DashboardNew/Blog/BlogArticle";
import Blogs from "../views/DashboardNew/Blog/Blogs";
import Categories from "../views/DashboardNew/Categories/Categories";
import Doctor from "../views/DashboardNew/Doctors/Doctor";
import UserProfile from "../views/user/UserProfile";
import NewPassword from "../views/UserLogin/NewPassword";
import ReferralUser from "../views/DashboardNew/Referral/ReferralUser";
import PrivacyPolicy from "../views/Privacy/PrivacyPolicy";

//Views
const Login = React.lazy(() => import('../views/Login/Login'));
const UserLogin = React.lazy(() => import('../views/UserLogin/Login'));
const Home = React.lazy(() => import('../views/Home/Home'));
const PagenotFound = React.lazy(() => import('../views/Error/PagenotFound'));
const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const UserRole = React.lazy(() => import('../views/UserRole/UserRole'));
const Registration = React.lazy(() => import('../views/Registration/Registration'));
const UserRegistration = React.lazy(() => import('../views/UserRegistration/Registration'));

const Routes = (props) => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/user-login" component={UserLogin} />
            <Route exact path="/user-role" component={UserRole}/>
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/user-registration" component={UserRegistration} />
            <Route exact path="/new-password" component={NewPassword} />

            <RouteWithLayout
                exact
                path="/dashboard/list"
                layout={DashboardLayout}
                component={Dashboard}
            />
            <RouteWithLayout
                exact
                path="/physicians-profile"
                layout={DashboardLayout}
                component={PhysiciansProfile}
            />

            {/* design Pages */}
            <RouteWithLayout exact path="/providers" layout={NewDashboardLayout} component={Providers} />
            <RouteWithLayout exact path="/providers/:category" layout={NewDashboardLayout} component={Providers} />
            <RouteWithLayout exact path="/doctor/:id" layout={NewDashboardLayout} component={Doctor} />
            <RouteWithLayout exact path="/popular-categories" layout={NewDashboardLayout} component={Categories} />
            <RouteWithLayout exact path="/blog" layout={NewDashboardLayout} component={Blogs} />
            <RouteWithLayout exact path="/blog/:id" layout={NewDashboardLayout} component={BlogArticle} />
            <RouteWithLayout exact path="/user-profile" layout={NewDashboardLayout} component={UserProfile} />
            <RouteWithLayout exact path="/referral-user" layout={NewDashboardLayout} component={ReferralUser}/>
            <Route exact path="/not-found" component={PagenotFound} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Redirect from="*" to="/not-found" />
        </Switch>
    )
}

export default Routes;